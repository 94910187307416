import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { FooterSubscription, ServiceText } from '~/components';
import Banner from '~/components/Banner';
import { HeaderPage, MenuPage } from '.';
import c from './FooterPage.sass';

interface Props {
  referrer: string | null
}

export const NewsLettersPage: FunctionComponent<Props> = ({ referrer = null }) => (
  <div className={c.newsletters}>
    <Banner />
    <div className={c.footer} id="footer">
      <MenuPage />
      <HeaderPage forPage />
      <div className={c.footer_bord} />
      <div
        className={`${c.container}`}
        id="footer-mailing"
      >
        <div className={c.wrap}>
          <div className={c.title}>
            <ServiceText name="mailing" />
          </div>
          <FooterSubscription referrer={referrer} />
          <div className={c.terms}>
            <div className={c.terms_poly}>
              <ServiceText name="legal_info" />
              <br />
              <Link to="/privacy_policy">Пользовательское соглашение.</Link>
            </div>
          </div>
          <div className={c.down}>
            <div className={c.down_copy}>
              © Reminder
              {new Date().getFullYear()}
            </div>
            <Link className={c.down_rss} target="_blank" to="/rss"><span>RSS</span></Link>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default NewsLettersPage;
