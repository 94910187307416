import React from 'react';

import c from './ItemList.sass';

interface Props {
  items: Array<React.FunctionComponent | React.ReactNode>;
  className?: string;
}

const ItemList: React.FunctionComponent<Props> = ({
  items, className,
}) => {
  const getClass = () => (className ? ` ${c[className]}` : '');
  return (
    <div className={c.container + getClass()}>
      {items.map((item, index) => <div key={index}>{item}</div>)}
    </div>
  );
};

ItemList.defaultProps = {
  className: '',
};

export default ItemList;
