import React, { FunctionComponent, useEffect } from 'react';
import { Page, Paginator } from '~/components';
import PostList from '~/components/post/PostList';
import { useConfig } from '~/resources';
import config from '~/config';
import JsonLd from '~/screens/jsonLd';
import Banner from '~/components/Banner';
import Helmeter from './Helmeter';
import { useResetScroll } from './screen';

const HomeScreen: FunctionComponent = () => {
  useResetScroll();

  const { status, fetchBy } = useConfig();

  useEffect(() => {
    fetchBy();
  }, []);

  // const { pathname } = useLocation();
  // const ampLink = `${config('APP_HOST')}/amp${pathname}`;
  const canonicalLink = config('APP_HOST');

  // if (status !== FetchStatus.Fetched) {
  //   return <Loader />;
  // }

  return (
    <>
      <Banner />
      <Page>
        <Helmeter
          link={[
            // { rel: 'amphtml', href: ampLink },
            { rel: 'canonical', href: canonicalLink },
          ]}
        />
        <JsonLd />
        <Paginator>
          {(page, handleMeta, handleLoading) => (
            <PostList isMainPage page={page} onMeta={handleMeta} onLoading={handleLoading} />
          )}
        </Paginator>
      </Page>
    </>
  );
};

export default HomeScreen;
