/* eslint-disable camelcase */
import React, { useState, useEffect, FunctionComponent } from 'react';
import { useLocation } from 'react-router';
import { Helmet } from 'react-helmet';

import config from '~/config';

interface Props {
  name?: string
}

const generateData = (name: string, location: any) => {
  const url = `${config('APP_HOST')}${location.pathname}`;
  const siteName = 'Reminder';
  let body: any = { '@context': 'http://schema.org' };
  if (name) {
    body = {
      ...body,
      '@type': 'NewsMediaOrganization',
      name,
      url,
    };
  } else {
    const alternateName = 'Reminder — издание о здоровье, саморазвитии и практической философии. Мы ищем ответы на самые важные вопросы: как принимать более качественные решения на работе и в личной жизни, как добиваться больших результатов, жить дольше и счастливее.';
    body = {
      ...body,
      '@graph': [
        {
          '@type': 'WebSite', url, name: siteName, alternateName,
        },
        { '@type': 'Organization', url, name: siteName },
      ],
    };
  }
  return body;
};

const JsonLd: FunctionComponent<Props> = ({ name = '' }) => {
  const location = useLocation();
  const [dataContent, setDataContent] = useState(generateData(name, location));

  useEffect(() => {
    setDataContent(generateData(name, location));
  }, [location, name]);

  return (
    <Helmet>
      <meta name="key" content="Reminder" />
      <script type="application/ld+json">
        {JSON.stringify(dataContent)}
      </script>
    </Helmet>
  );
};

export default JsonLd;
