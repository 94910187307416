import queryString from 'query-string';
import { match as Match } from 'react-router';

import useResource, { ResourceResult, Resource, QueryParams } from './useResource';
import { Post } from './models';
import config from '~/config';
import * as utils from '~/utils';
import anyFetch from './anyFetch';
import Auth from './Auth';

const PATH = () => `${config('API_HOST')}`;

export const postFetcher = (slug = 'posts') => async (
  params: QueryParams = {},
  opts: RequestInit = {},
): Promise<Resource<Post[]>> => {
  let path = `${PATH()}/${slug}`;
  const fillParams = {
    ...params,
    status: ['published', 'announce'],
    sort: 'published_at',
  };
  const paramsStr = queryString.stringify(fillParams, {
    arrayFormat: 'bracket',
  });

  if (paramsStr) {
    path += `?${paramsStr}`;
  }
  const res = await anyFetch(path, opts);
  const data = await res.json();
  return data;
};

const generateCacheKey = (params: QueryParams = {}, slug = 'posts') =>
  `${slug}${JSON.stringify(params)}`;

const usePosts = (
  params: QueryParams = {},
  opts: RequestInit = {},
  slug?: string,
): ResourceResult<Post[]> =>
  useResource(params, opts, postFetcher(slug), generateCacheKey(params, slug));

export default usePosts;

export const fetchSSRPosts = async (
  auth: Auth,
  path: string,
  type: string,
  match: Match<{ topic?: string; rubric?: string }>,
  opts: RequestInit = {},
  slug = 'posts',
): Promise<any> => {
  const { topic, rubric } = match.params;
  const pageParam = new URLSearchParams(utils.getSearch(path)).get('page');
  const pageInt = pageParam ? parseInt(pageParam, 10) : 1;
  const page = pageInt > 0 ? String(pageInt) : '1';
  const params: QueryParams = { type, page: String(page) };

  if (topic) {
    params.topic_id = topic;
  }
  if (rubric) {
    params.rubric_id = rubric;
  }

  const res = await postFetcher(slug)(params, opts);
  auth.setCache(generateCacheKey(params, slug), res);
};
