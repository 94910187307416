/* eslint-disable no-irregular-whitespace */
import React, { ReactElement } from 'react';
import Modal from 'react-modal';
import { useHistory } from 'react-router';
import disableScroll from '~/utils/disableScroll';
import c from './Alert.sass';
import {
  FailIcon, MailIcon, GiftIcon, SuccessIcon,
} from './Icons';
import { CloseIcon } from '../Auth/Icons';
import {
  AlertModalType, AuthModalFrom, AuthModalState, usePageContext,
} from '../page';

interface ModalContent {
  icon: ReactElement,
  title: string,
  text: ReactElement | string,
  btnText: string
  onClick: () => void,
}

const AlertModal = (): ReactElement | null => {
  const history = useHistory();
  const { alertModal, authModal } = usePageContext();
  if (!alertModal) {
    return null;
  }
  const [alertContent, setAlertContent] = alertModal;

  if (alertContent.alertType === AlertModalType.NONE) {
    return null;
  }

  const getDate = (date: Date | undefined) => {
    if (!date) { return '_'; }
    return date.toLocaleString('default', { day: 'numeric', month: 'long' });
  };

  const onClose = () => {
    setAlertContent({
      alertType: AlertModalType.NONE,
      email: '',
      date: undefined,
    });
    disableScroll(false);
    const currentPath = window.location.pathname;
    if (currentPath.includes('/verify') || currentPath.includes('/changepassword')) {
      history.push('/');
    }
  };

  const openAuthModal = () => {
    onClose();
    if (authModal) {
      authModal[1]({ authModalState: AuthModalState.CHECK, authModalFrom: AuthModalFrom.NONE });
      alertModal[1]({ alertType: AlertModalType.NONE });
    }
  };

  const getContentData = (type: AlertModalType): ModalContent | null => {
    switch (type) {
      case AlertModalType.CONFIRM:
        return {
          title: 'Подтверждение регистрации',
          icon: <MailIcon />,
          btnText: 'Хорошо, спасибо',
          onClick: onClose,
          text: (
            <p className={c.content}>
              Мы отправили вам письмо на почту
              {' '}
              <b>{alertContent.email}</b>
              .
              Если письмо не пришло, проверьте папку «Спам» или напишите нам:
              <br />
              <u>newsletter@reminder.media</u>
              .
            </p>
          ),
        };
      case AlertModalType.WITHOUT_PASSWORD:
        return {
          title: 'Вход без пароля',
          icon: <MailIcon />,
          btnText: 'Хорошо, спасибо',
          onClick: onClose,
          text: (
            <p className={c.content}>
              Проверьте почту
              {' '}
              <b>{alertContent.email}</b>
              {' '}
              — мы отправили вам письмо с ссылкой для входа без пароля. Ссылка будет работать
              15&nbsp;минут.
              <br />
              <br />
              Если письмо не пришло, проверьте папку «Спам» или напишите нам:
              <br />
              <u>newsletter@reminder.media</u>
              .
            </p>
          ),
        };
      case AlertModalType.FAIL:
        return {
          title: 'Упс',
          icon: <FailIcon />,
          btnText: 'Попробовать ещё раз',
          onClick: openAuthModal,
          text: `Ссылка больше не работает. Попробуйте еще раз войти без пароля
          или выберите другой способ входа.`,
        };
      case AlertModalType.ERROR:
        return {
          title: 'Упс',
          icon: <FailIcon />,
          btnText: 'Попробовать ещё раз',
          onClick: openAuthModal,
          text: 'Что-то пошло не так :(',
        };
      case AlertModalType.GIFT_SUCCESS:
        return {
          title: 'Ура!',
          icon: <GiftIcon />,
          btnText: 'Закрыть',
          onClick: onClose,
          text: `Мы отправим ваш подарок сейчас. Спасибо, что выбрали подарить 
          подписку на Reminder — нам приятно.`,
        };
      case AlertModalType.GIFT_SUCCESS_DATE:
        return {
          title: 'Ура!',
          icon: <GiftIcon />,
          btnText: 'Попробовать ещё раз',
          onClick: onClose,
          text: `Мы отправим ваш подарок ${getDate(alertContent.date)} в 8 утра по МСК. 
          Спасибо, что выбрали подарить подписку на Reminder — нам приятно.`,
        };
      case AlertModalType.PAYMENT_FAIL:
        return {
          title: 'Упс',
          icon: <FailIcon />,
          btnText: 'Попробовать ещё раз',
          onClick: onClose,
          text: `Попробуйте оплатить снова. Если не получилось — введите другую 
          карту или напишите нам: newsletter@reminder.media.`,
        };
      case AlertModalType.SUBSCRIPTION_GIFT_SUCCESS:
        return {
          title: 'Ура!',
          icon: <GiftIcon />,
          btnText: 'Хорошо, спасибо',
          onClick: () => {
            history.push('/');
            onClose();
          },
          text: (
            <p className={c.content}>
              Мы отправили сертификат вам на почту. Если письмо не пришло, проверьте
              папку «Спам» или напишите нам:
              {' '}
              <u><a href="mailto:newsletter@reminder.media">newsletter@reminder.media</a></u>
              .
            </p>
          ),
        };
      case AlertModalType.SUBSCRIPTION_GIFT_FAIL:
        return {
          title: 'Упс',
          icon: <FailIcon />,
          btnText: 'Попробовать ещё раз',
          onClick: () => {
            history.push('/gift');
            onClose();
          },
          text: (
            <p className={c.content}>
              Попробуйте оплатить снова. Если не получилось — введите другую карту
              или напишите нам:
              {' '}
              <u><a href="mailto:newsletter@reminder.media">newsletter@reminder.media</a></u>
              .
            </p>
          ),
        };
      case AlertModalType.PASSWORD_CHANGED_SUCCESS:
        return {
          title: 'Пароль изменен',
          icon: <SuccessIcon />,
          btnText: 'Хорошо, спасибо',
          onClick: onClose,
          text: (
            <span className={c.content}>
              Если забудете пароль, вы
              <br />
              всегда сможете войти без него
            </span>
          ),
        };
      case AlertModalType.PASSWORD_CHANGE_FAIL:
        return {
          title: 'Упс',
          icon: <FailIcon />,
          btnText: 'Попробовать ещё раз',
          onClick: () => {
            history.push('/profile');
            onClose();
          },
          text: `Ссылка больше не работает. Попробуйте еще раз сменить пароль
          через личный кабинет.`,
        };
      case AlertModalType.PASSWORD_CHANGE_EMAIL:
        return {
          title: 'Изменение пароля',
          icon: <MailIcon />,
          btnText: 'Хорошо, спасибо',
          onClick: onClose,
          text: (
            <span className={c.content}>
              Мы отправили вам письмо на почту
              {' '}
              <b>{alertContent.email}</b>
              . Если письмо не&nbsp;пришло, проверьте папку «Спам» или напишите нам:
              <br />
              <u>newsletter@reminder.media</u>
            </span>
          ),
        };
      default: return null;
    }
  };

  const getContent = (type: AlertModalType) => {
    const content = getContentData(type);
    if (!content) { return <></>; }

    return (
      <>
        <div className={c.title}>{content.title}</div>
        {content.icon}
        <p className={`${c.center} ${c.content}`}>
          {content.text}
        </p>
        <button
          type="button"
          className={`${c.btn} ${c.btn_provider}`}
          onClick={content.onClick}
        >
          {content.btnText}
        </button>
      </>
    );
  };

  return (
    <Modal
      isOpen
      ariaHideApp={false}
      overlayClassName={c.modal}
      shouldCloseOnOverlayClick={false}
      className={c.modal__content}
      style={{ overlay: { zIndex: 10000 } }}
    >
      <button
        type="button"
        onClick={onClose}
        className={`${c.icon} ${c.icon_close}`}
      >
        <CloseIcon />
      </button>
      {getContent(alertContent.alertType)}
    </Modal>
  );
};

export default AlertModal;
