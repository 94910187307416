import React, { FunctionComponent } from 'react';
import { MailingStatus } from '~/resources/useMailing';

const MESSAGE_SUCCESS = 'Есть. Следующее наше письмо будет у вас в почте!';
const MESSAGE_EXIST = 'Кажется, вы уже подписаны. Если нет, то напишите нам: ';
const MESSAGE_ERROR = 'Что-то пошло не так. Напишите нам, если не удалось подписаться: ';

const MailLink: FunctionComponent = () => (
  <a href="mailto:newsletter@reminder.media">newsletter@reminder.media</a>
);

const SubscriptionMessage: FunctionComponent<{status: string}> = ({ status }) => {
  switch (status) {
    case MailingStatus.OK: {
      return (
        <>
          { MESSAGE_SUCCESS }
        </>
      );
    }
    case MailingStatus.EXISTS: {
      return (
        <>
          { MESSAGE_EXIST }
          <MailLink />
        </>
      );
    }
    case MailingStatus.ERROR: {
      return (
        <>
          { MESSAGE_ERROR }
          <MailLink />
        </>
      );
    }
    default: {
      return null;
    }
  }
};

export default SubscriptionMessage;
