/* eslint-disable camelcase */
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import c from './PreviewNews.sass';
import { Post } from '~/resources/models';

type Props = {
  post: Post;
};

const PreviewNews: FunctionComponent<Props> = ({ post }) => {
  const {
    topic, title, slug,
  } = post;

  const urlTopic = topic && `/topic/${post.topic.slug}`;
  const urlNews = `/post/${slug}`;

  return (
    <div>
      <Link to={urlTopic} className={c.topic}>
        {topic.title}
      </Link>
      <Link to={urlNews} className={c.title}>
        {title}
      </Link>
    </div>
  );
};

PreviewNews.displayName = 'PreviewNews';

export default PreviewNews;
