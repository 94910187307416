import { useRouteMatch } from 'react-router';
import {
  AuthModalFrom, AuthModalState, SubscriptionFlowStep, SubscriptionType, usePageContext,
} from '~/components';
import disableScroll from '~/utils/disableScroll';
import { FetchStatus } from './useResource';
import { SubscriptionPlan } from './models';

const useSubscriptionFlow = () => {
  const { url } = useRouteMatch();
  const {
    subscriptionFlow, authModal, profile, profileStatus, alertModal,
  } = usePageContext();

  const subscriptionFlowVal = subscriptionFlow && subscriptionFlow[0];
  const setSubscriptionFlow = subscriptionFlow && subscriptionFlow[1];
  const setAuthModal = authModal && authModal[1];
  const profileVal = profile && profile[0];
  const profileStatusVal = profileStatus && profileStatus[0];

  const buySubscription = (plan: SubscriptionPlan, type: SubscriptionType, successUrl: string) => {
    if (!setSubscriptionFlow) {
      return;
    }
    if (!profileStatusVal || profileStatusVal === FetchStatus.Fetching) {
      return;
    }
    if (profileVal) {
      setSubscriptionFlow({
        step: SubscriptionFlowStep.PAYMENT,
        subscriptionStartUrl: url,
        plan,
        type,
        successUrl,
      });
    } else if (setAuthModal) {
      setSubscriptionFlow({
        step: SubscriptionFlowStep.AUTH,
        subscriptionStartUrl: url,
        plan,
        type,
        successUrl,
      });
      disableScroll(true);
      setAuthModal({
        authModalState: AuthModalState.CHECK,
        authModalFrom: AuthModalFrom.PLANS,
      });
    }
  };

  const buyAfterAuth = () => {
    if (!setSubscriptionFlow) {
      return;
    }
    if (!subscriptionFlowVal) {
      return;
    }
    if (subscriptionFlowVal.step !== SubscriptionFlowStep.AUTH) {
      return;
    }
    setSubscriptionFlow({
      ...subscriptionFlowVal,
      step: SubscriptionFlowStep.PAYMENT,
    });
  };

  const checkPayment = () => {
    if (!setSubscriptionFlow) {
      return;
    }
    if (!subscriptionFlowVal) {
      return;
    }
    if (subscriptionFlowVal.step !== SubscriptionFlowStep.PAYMENT) {
      return;
    }
    setSubscriptionFlow({
      ...subscriptionFlowVal,
      step: SubscriptionFlowStep.CHECK,
    });
  };

  const cancelSubscription = () => {
    if (!setSubscriptionFlow) {
      return;
    }
    setSubscriptionFlow(undefined);
  };

  return {
    subscriptionFlowVal,
    buySubscription,
    buyAfterAuth,
    cancelSubscription,
    checkPayment,
  };
};

export default useSubscriptionFlow;
