import React, { FC, useEffect, useState } from 'react';
import {
  Link, useHistory, useLocation, useRouteMatch,
} from 'react-router-dom';
import useSubscription from '~/resources/useSubscription';
import { SubscriptionPlan } from '~/resources/models';
import useSubscriptionFlow from '~/resources/useSubscriptionFlow';
import { SubscriptionStatus } from '~/utils/purshase-event';
import useProfile from '~/resources/useProfile';
import c from './SubscriptionComponent.sass';
import ServiceText from '../ServiceText';
import PaidSubscriptionReviews from './PaidSubscriptionReviews';
import { SubscriptionForm } from './SubscriptionForm';
import GiftIcon from './Giftcon';
import Loader from '../Loader';
import { SubscriptionFlowStep, SubscriptionType, usePageContext } from '../page';
import { SubscriptionPayment } from './SubscriptionPayment';
import { SubscriptionStatusModal } from './SubscriptionStatusModal';
import { SubscriptionCheck } from './SubscriptionCheck';

interface Props {
  discountPromocode: string | undefined;
}

const SubscriptionComponent: FC<Props> = ({ discountPromocode }) => {
  const { url } = useRouteMatch();
  const { fetchBy: fetchPlans, data: plans } = useSubscription({
    discount_promocode: discountPromocode || '',
  });
  const location = useLocation();
  const utmCampaign = new URLSearchParams(location.search).get('utm_campaign');
  const utmSource = new URLSearchParams(location.search).get('utm_source');
  const utm = (utmCampaign || utmSource)
    ? JSON.stringify({ utmCampaign, utmSource }) : undefined;
  const {
    buySubscription,
    subscriptionFlowVal,
    cancelSubscription,
    checkPayment,
  } = useSubscriptionFlow();
  const { profile, auth } = usePageContext();
  const { data: profileData, fetchBy: fetchProfile } = useProfile();
  const step = subscriptionFlowVal && subscriptionFlowVal.step;
  const plan = subscriptionFlowVal && subscriptionFlowVal.plan;
  const [finalStatus, setFinalStatus] = useState<SubscriptionStatus>();
  const [orderId, setOrderId] = useState<number>();
  const history = useHistory();

  useEffect(() => {
    fetchPlans();
  }, []);

  useEffect(() => {
    if (!profileData || !profile || !profile[1]) { return; }
    profile[1](profileData);
  }, [profileData]);

  const buy = (selectedPlan: SubscriptionPlan) => {
    buySubscription(selectedPlan, SubscriptionType.NEW, url);
  };

  const onStatus = (status: SubscriptionStatus) => {
    if (status === SubscriptionStatus.PAYMENT_CHECK_PROCESSED) {
      fetchProfile();
      auth?.clearCache();
    }
    setFinalStatus(status);
    cancelSubscription();
  };

  const onSuccessPayment = (oId: number) => {
    setOrderId(oId);
    checkPayment();
  };

  const cancel = () => {
    setFinalStatus(undefined);
    cancelSubscription();
    fetchProfile();
    fetchPlans();
  };

  const renderInactiveDiscountPromocode = () => (
    <div className={c.subscription_promo_error}>
      <h1>Промокод не работает, попробуйте другой</h1>
      <button
        onClick={() => history.push('/subscription')}
        type="button"
        className={c.pay_button}
      >
        Попробовать ещё
      </button>
    </div>
  );

  const renderForm = () => {
    if (!plans) return (<Loader />);
    if (plans.length === 0) {
      return renderInactiveDiscountPromocode();
    }
    return (<SubscriptionForm plans={plans} onSubmit={buy} />);
  };

  const renderPayment = () => (step === SubscriptionFlowStep.PAYMENT && plan
    ? (
      <SubscriptionPayment
        plan={plan}
        promocode={discountPromocode || ''}
        onError={onStatus}
        onSuccess={onSuccessPayment}
        onClose={cancel}
        utm={utm}
      />
    )
    : null
  );

  const renderCheck = () => (step === SubscriptionFlowStep.CHECK && orderId && (
    <SubscriptionCheck
      orderId={orderId}
      onClose={cancel}
      onCheck={onStatus}
    />
  ));

  const renderStatus = () => (finalStatus && (
    <SubscriptionStatusModal status={finalStatus} onClose={cancel} />
  ));

  return (
    <div className={c.wrapper}>
      <div className={c.container1}>
        <div className={c.title}>Подписка</div>
        <div className={c.description}>
          <ServiceText name="paywall_billing_title" />
        </div>
        {/* <div className={c.scrollbarwrapper}>
          <hr />
        </div> */}
      </div>
      {renderForm()}
      {renderPayment()}
      {renderCheck()}
      {renderStatus()}
      {/* <hr /> */}
      <div className={c.container5}>
        <div className={c.article}>
          <ServiceText name="paywall_billing_footer" />
        </div>
        <div className={c.policy}>
          <a href="/public_offer_subscription" target="_blank">
            Оферта
          </a>
          {' '}
          и
          {' '}
          <a href="/privacy_policy" target="_blank">
            политика конфиденциальности
          </a>
        </div>
      </div>
      <hr />
      <Link
        className={c.btn}
        to="/gift"
      >
        <GiftIcon />
        Купить в подарок
      </Link>
      <hr />
      <PaidSubscriptionReviews />
    </div>
  );
};

export default SubscriptionComponent;
