import useResource, { ResourceResult, Resource, QueryParams } from './useResource';
import {  SubscriptionReview } from './models';
import config from '~/config';
import anyFetch from './anyFetch';
import Auth from './Auth';

const PATH = () => `${config('API_HOST')}/subscription_reviews`;

export const reviewsFetcher = async (
  params: QueryParams = {},
  opts: RequestInit = {},
): Promise<Resource<SubscriptionReview[]>> => {
  const res = await anyFetch(PATH(), opts);
  const data = await res.json();
  return data;
};
const cacheKey = 'subscription_reviews';

const useSubscriptionReviews = (
  params: QueryParams = {},
  opts: RequestInit = {},
): ResourceResult<SubscriptionReview[]> =>
  useResource(params, opts, reviewsFetcher, cacheKey);

export default useSubscriptionReviews;

export const fetchSSRSubscriptionReviews = async (
  auth: Auth,
  opts: RequestInit = {},
): Promise<any> => {
  const res = await reviewsFetcher({}, opts);
  auth.setCache(cacheKey, res);
};
