import queryString from 'query-string';

import { match as Match } from 'react-router';
import useResource, {
  ResourceResult, Resource, QueryParams,
} from './useResource';
import { Post } from './models';
import config from '~/config';
import anyFetch from './anyFetch';
import Auth from '~/resources/Auth';

const PATH = () => `${config('API_HOST')}/search`;

export const postFetcher = async (
  params: QueryParams = {},
  opts: RequestInit = {},
): Promise<Resource<Post[]>> => {
  let path = PATH();
  const fillParams = {
    ...params,
    status: ['published', 'announce'],
  };
  const paramsStr = queryString.stringify(fillParams, {
    arrayFormat: 'bracket',
  });

  if (paramsStr) {
    path += `?${paramsStr}`;
  }
  const res = await anyFetch(path, opts);
  const data = await res.json();
  return data;
};

const generateCacheKey = (search:string, page: number) => `search?search=${search}&page=${page}`;

const useSearch = (
  params: QueryParams = {},
  opts: RequestInit = {},
): ResourceResult<Post[]> => {
  const { search, page } = params;
  return useResource(
    params,
    opts,
    postFetcher,
    generateCacheKey(String(search), Number(page)),
  );
};

export const fetchSSRSearch = async (
  auth: Auth,
  path: string,
  opts: RequestInit = {},
): Promise<any> => {
  const url = new URL(`${config('APP_HOST')}/${path}`);
  let search = url.searchParams.get('search') || '';
  search = search.replace('<script>', '').replace('</script>', '');
  let page = Number(url.searchParams.get('page')) || 1;
  const fetching = async (p: number) => {
    const res = await postFetcher({ search, page: String(p) }, opts);
    auth.setCache(generateCacheKey(search, p), res);
    // eslint-disable-next-line camelcase
    const lastPage = res?.meta?.last_page;
    if (lastPage && lastPage < page) {
      page = lastPage;
    }
    if (p < page) {
      await fetching(p + 1);
    }
  };
  if (search) {
    await fetching(1);
  }
};

export default useSearch;
