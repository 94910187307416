import React, { FunctionComponent, Fragment, useEffect } from 'react';
import usePosts from '~/resources/usePosts';
import { Loader } from '~/components';
import PreviewPost from './PreviewPost';
import { ResourceMeta, QueryParams } from '~/resources';
import { ListNews } from '../News';
import { PreviewSoc } from '../soc';
import { PreviewSuper } from '../super';
import c from './PostList.sass'
import ItemList from '../ItemList/ItemList';
import { TagList } from '../Tag';
import { DailyList } from '../DailyList';

interface Props {
  page: number;
  onMeta: (meta: ResourceMeta) => void,
  onLoading: (loading: boolean) => void,
  isMainPage?: boolean | undefined,
}

const PostList: FunctionComponent<Props> = ({
  page, onMeta, onLoading, isMainPage,
}) => {
  const params: QueryParams = { type: 'article', page: String(page) };
  const {
    meta, fetchBy, data: posts,
  } = usePosts(params, {}, 'main_page');

  useEffect(() => {
    fetchBy();
  }, [page]);

  useEffect(() => {
    if (meta) {
      onMeta(meta);
    }
  }, [meta]);

  useEffect(() => {
    onLoading(!posts);
  }, [posts]);

  const items = () => {
    const items = new Array();

    if (!posts) return items;

    posts.forEach((post, i) => {
      // if (page === 1 && i === 0) {
      //   items.push(<DailyList />);
      //   items.push(<TagList />);
      // }

      if (page === 1 && i === 2) {
        items.push(<ListNews />);
      }

      // if (page === 1 && i === 8) {
      //   items.push(<PreviewSoc />);
      // }

      if (post.preview_mode === 'preview_super') {
        items.push(<PreviewSuper post={post} />);
      } else {
        items.push(<PreviewPost post={post} />);
      }
    });

    return items;
  }

  if (!posts) {
    return <Loader />;
  }

  return (
    <ItemList className={isMainPage ? 'onMainPage' : ''} items={items()} />
  );
};

PostList.defaultProps = {
  isMainPage: false,
}

export default PostList;
