import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import c from './Topic.sass';

interface Props {
    title?: string
    subtitle?: string
    href?: string
    showDot?: boolean,
    colorOrange?: boolean
}

const Topic: FunctionComponent<Props> = ({
  title, subtitle, href, showDot = false, colorOrange = false,
}) => (
  <div className={c.topic + (showDot ? ` ${c.topic_dot}` : '') + (colorOrange ? ` ${c.topic_orange}` : '')}>
    {title && (
      href ? (
        <Link to={href} className={`${c.item} ${c.item_isBlack}`}>{title}</Link>
      ) : (
        <div className={`${c.item} ${c.item_isBlack}`}>{title}</div>
      )
    )}
    {subtitle && (
    <div className={`${c.item} ${c.item_subtitle}`}>{subtitle}</div>
    )}
  </div>
);

export default Topic;
