import React, { FC } from 'react';

import c from './Paywall.sass';

export interface PaywallProps {
  onClick: ()=>void,
  isProfie: boolean
}

const PaywallHeader: FC<PaywallProps> = ({ onClick, isProfie }) => (
  <div className={c.header_wrapper}>
    <div>
      <span> Подпишитесь, чтобы дочитать статью </span>
      и получить полный
      доступ ко&nbsp;всем материалам и&nbsp;закрытым мероприятиям
    </div>
    {!isProfie ? (
      <button
        className={c.header_already_sub}
        type="button"
        onClick={onClick}
      >
        У меня есть подписка
      </button>
    ) : null }
  </div>
);

PaywallHeader.displayName = 'PaywallHeader';

export default PaywallHeader;
