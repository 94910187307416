import React, { FunctionComponent, useEffect, useState } from 'react';
import { NavLink, Link } from 'react-router-dom';

import useConfig from '~/resources/useConfig';
import { HeaderPage, usePageContext } from '.';
import { MenuSubscription } from '../Subscription';
// import { LoginPage } from './login.page';
// import { MenuSoc } from '../soc';
import Asterisk from '../AsteriskHeader';
import c from './MenuPage.sass';
import LoginPage from './LoginPage';
import SubscribePage from './SubscribePage';

const MenuPage: FunctionComponent = () => {
  const { data, fetchBy } = useConfig();
  const banner = data?.banner;
  const topics = data ? data.topics : [];
  const [hide, setHide] = usePageContext().hideMenu;
  const [heightMenu, setHeightMenu] = useState(0);
  const [profile, setProfile] = usePageContext().profile;

  useEffect(() => {
    fetchBy();
  }, []);

  useEffect(() => {
    const onResize = () => setHeightMenu((window.innerHeight * 0.01) * 100);
    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  return (
    <div className={`${c.menu} ${c[`menu-hide-${hide}`]}`}>
      <div className={`${c.menu_wrap} ${banner ? c.with_banner : ''}`} style={{ height: heightMenu }}>
        <div className={c.menu_header}>
          <HeaderPage isCross closeOverlay />
        </div>
        <div className={c.asterisk}>
          <Asterisk />
        </div>
        <div className={c.menu_footer}>
          <nav className={c.menu_list}>
            <LoginPage />
            <ul className={c.menu_topics}>
              {topics && topics.map((topic) => (
                <li key={topic.id}>
                  <NavLink
                    to={`/topic/${topic.slug}`}
                    activeClassName={c.active}
                    onClick={() => setHide(true)}
                  >
                    {topic.title}
                  </NavLink>
                </li>
              ))}
            </ul>
            <ul>
              {/* <li>
                <NavLink
                  to="/donate"
                  className={c.menu_activeLink}
                  onClick={() => setHide(true)}
                >
                  Поддержать
                </NavLink>
              </li> */}
              {/*
                <li>
                {/* <a
                  className={c.menu_activeLink}
                  href="https://shop.reminder.media/?utm_source=site&utm_medium=menu&utm_campaign=shop"
                  target="_blank"
                  rel="noreferrer"
                >
                  Магазин
                </a> * /}
                <NavLink
                  to="/reports"
                  activeClassName={c.active}
                  onClick={() => setHide(true)}
                >
                  Магазин
                </NavLink>
              </li>
              */}
              <SubscribePage onClick={() => setHide(true)} />
            </ul>
          </nav>
          <MenuSubscription>
            <ul>
              <li>
                <NavLink
                  to="/search"
                  className={c.menu_search}
                  activeClassName={c.active}
                  onClick={() => setHide(true)}
                >
                  Поиск
                </NavLink>
              </li>
              <li>
                <NavLink
                  exact
                  to="/about"
                  className={c.menu_search}
                  activeClassName={c.active}
                  onClick={() => setHide(true)}
                >
                  О проекте
                </NavLink>
              </li>
            </ul>
          </MenuSubscription>
        </div>

      </div>
    </div>
  );
};

MenuPage.displayName = 'MenuPage';
export default MenuPage;
