import React, { FunctionComponent, useEffect, useState } from 'react';

import { MailingStatus } from '~/resources/useMailing';
import { ServiceText, SubscriptionMessage } from '~/components';
import { SubscriptionForm } from '..';
import c from './FooterSubscription.sass';
import { useConfig } from '~/resources';

interface Props {
  referrer?: string | null
}

const FooterSubscription: FunctionComponent<Props> = ({ referrer = null }) => {
  const [status, setStatus] = useState<MailingStatus | undefined>();

  useEffect(() => {
    if (typeof window !== 'undefined' && typeof (window as any).ym !== 'undefined') {
      window.ym(67715335, 'reachGoal', 'newsletters_footer_show');
    }
  }, []);

  return (
    <>
      {status ? (
        <div className={c.desc}>
          <span>
            <SubscriptionMessage status={status} />
          </span>
        </div>
      ) : (
        <>
          <div className={c.desc}>
            <ServiceText name="mailing_text_bottom" />
          </div>
          <SubscriptionForm
            tags={['newsletters1']}
            onChangeStatus={setStatus}
            referrer={referrer}
          />
        </>
      )}
    </>
  );
};

export default FooterSubscription;
