import React, {
  FunctionComponent, useEffect, useState, useRef, useMemo,
} from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { useConfig } from '~/resources';
import { LogoPage, BurgerPage, usePageContext } from '.';

import c from './HeaderPage.sass';

interface Props {
  isCross?: boolean
  forPage?: boolean
  closeOverlay?: boolean
}

const BANNER_HEIGHT = 30;

const HeaderPage: FunctionComponent<Props> = ({
  forPage, isCross, closeOverlay = false,
}) => {
  const { data: config } = useConfig();
  const banner = config?.banner;
  const ref = useRef<HTMLDivElement>(null);
  const { hideMenu } = usePageContext();
  const { path } = useRouteMatch();
  const lastScroll = useRef(0);
  const lastTop = useRef(0);
  const lastBorder = useRef(false);
  const [scroll, setScroll] = useState(0);
  const [bannerHeight, setBannerHeight] = useState(0);

  useEffect(() => {
    setBannerHeight(BANNER_HEIGHT);
  }, [banner])

  const onScroll = () => {
    const newScroll = window.pageYOffset;
    setScroll((s: number) => {
      lastScroll.current = s;
      return newScroll;
    });
  };

  useEffect(() => {
    onScroll();
    document.addEventListener('scroll', onScroll);
    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, []);

  if (!hideMenu) {
    return null;
  }
  const toggleMenu = () => hideMenu[1]((d) => !d);

  const scrollLogic = () => {

    let top = 0;
    let topFix = false;
    let border = lastBorder.current;
    if (lastTop.current < 0 || scroll <= 0) {
      top = 0;
      border = false;
      topFix = true;
    } else if (ref.current) {
      const h = +ref.current.clientHeight + 1 + bannerHeight;
      const isUp = lastScroll.current > scroll;
      const isDown = lastScroll.current <= scroll;
      if (isUp) {
        const isHoldUp = lastTop.current + bannerHeight < scroll;
        if (isHoldUp) {
          top = lastTop.current;
        } else if (banner && scroll < BANNER_HEIGHT && scroll > 0) {
          top = 0;
        } else {
          top = scroll - bannerHeight;
          topFix = true;
        }
      } else if (isDown) {
        const isHoldDown = lastTop.current > scroll - h;
        if (isHoldDown) {
          top = lastTop.current;
        } else {
          top = scroll - h;
          if (scroll > h) {
            border = true;
          }
        }
      }
    }
    lastTop.current = top;
    lastBorder.current = border;
    return { top, border, topFix };
  };

  const { top, border, topFix } = scrollLogic();
  const isSuperPost = path === '/super/:slug' || path === '/longread/:slug' || path === '/report/:slug';

  return (
    <>
      <div
        ref={ref}
        style={{ top }}
        className={
          c.header
          + (forPage ? ` ${c.page_header}` : '')
          + (isSuperPost ? ` ${c.fixed}` : '')
          + (border ? ` ${c.border}` : '')
          + (topFix ? ` ${c.topFix}` : '')
          + ((bannerHeight && scroll <= 0) ? ` ${c.withBanner}` : '')
        }
      >
        <Link
          to="/"
          className={c.header_logo}
          aria-label="Логотип сайта"
        >
          <LogoPage />
        </Link>
        <button
          type="button"
          className={c.header_burger}
          onClick={toggleMenu}
        >
          <BurgerPage isCross={isCross} />
        </button>
      </div>
      <div
        className={c.header_overlay}
        style={closeOverlay ? { display: 'none' } : undefined}
      />
    </>
  );
};

export default HeaderPage;
