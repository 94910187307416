import React, {
  FunctionComponent, Fragment, useState, ReactElement, useEffect,
} from 'react';
import { useLocation } from 'react-router-dom';

import { ResourceMeta } from '~/resources/useResource';
import More from './More';

interface Props {
  children: (
    page: number,
    handleMeta: (meta: ResourceMeta) => void,
    handleLoading: (loading: boolean) => void,
  ) => ReactElement,
  onMeta?: (meta: ResourceMeta) => void,
  onLoading?: (loading: boolean) => void,
}

const Paginator: FunctionComponent<Props> = ({ children, onMeta, onLoading }) => {
  const location = useLocation();
  const [meta, setMeta] = useState<ResourceMeta>();
  const pageParam = new URLSearchParams(location.search).get('page');
  const pageInt = pageParam ? parseInt(pageParam, 10) : 1;
  const pageInit = pageInt > 0 ? pageInt : 1;
  const [{ page, pages }, setPage] = useState({
    page: pageInit,
    pages: Array.from(Array(pageInit).keys()).map((val) => (val + 1)),
  });
  const [loadings, setLoadings] = useState({ pageInit: false });
  const loading = Object.values(loadings).some((l) => l);
  useEffect(() => {
    if (onLoading) {
      onLoading(loading);
    }
  }, [loading]);

  const handleMeta = (p: number) => (m: ResourceMeta) => {
    if (p === page) {
      setMeta(m);
      if (onMeta) {
        onMeta(m);
      }
    }
  };

  const handleLoading = (p: number) => (l: boolean) => {
    setLoadings({ ...loadings, [p]: l });
  };

  const onMore = () => {
    setPage({
      page: page + 1,
      pages: [...pages, page + 1],
    });
  };

  const urlSearchParams = new URLSearchParams(location.search);
  urlSearchParams.set('page', String(page + 1));
  const nextUrl = `${location.pathname}?${urlSearchParams.toString()}`;

  return (
    <>
      {pages.map((p) => (
        <Fragment key={p}>
          {children(p, handleMeta(p), handleLoading(p))}
        </Fragment>
      ))}
      {!loading && meta && page < meta.last_page && (
        <More
          onClick={onMore}
          url={nextUrl}
        />
      )}
    </>
  );
};

export default Paginator;
