import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import { Page } from '~/components';
import Helmeter from './Helmeter';

import c from '~/components/post/ContentPost.sass';
import { useResetScroll } from './screen';
import Banner from '~/components/Banner';

const NotFoundScreen: FunctionComponent = () => {
  useResetScroll();
  return (
    <>
      <Banner />
      <Page>
        <Helmeter
          title="404"
          metaTitle="404"
        />
        <div className={c.container}>
          <div className={c.wrap} />
          <h2>404*</h2>
          <br />
          <span>
            Такой страницы нет. Есть другие, и очень много. Чтобы найти их,
            {' '}
            <Link to="/"> начните с главной</Link>
            .
          </span>
        </div>
      </Page>
    </>

  );
};

export default NotFoundScreen;
