import React from 'react';
import { Switch, Route } from 'react-router-dom';
import useAnalytics from '~/utils/Analytics';

import { ProviderPage } from '~/components';
import routes from './routes';
import './style.sass';
import Auth from './resources/Auth';
import Helmeter from './screens/Helmeter';

interface Props {
  auth: Auth,
}

const App: React.FunctionComponent<Props> = ({ auth }) => {
  useAnalytics();
  return (
    <ProviderPage auth={auth}>
      <Helmeter
        title="Reminder"
        metaTitle="Reminder"
        metaDescription="Reminder — это медиа о здоровье, саморазвитии и практической философии."
      />
      <Switch>
        {routes.map(({ path, component, exact }) => (
          <Route key={path} {...{ path, component, exact }} />
        ))}
      </Switch>
    </ProviderPage>
  );
};

export default App;
