import React, {
  FunctionComponent, useEffect, useRef, useState,
} from 'react';
import { Link } from 'react-router-dom';
import { FooterSubscription, ServiceText, ItSummaLogo } from '~/components';
import { LogoPage } from '.';
import c from './FooterPage.sass';

interface Props {
    className?: string
}

export const FooterPage: FunctionComponent<Props> = ({
  className,
}) => {
  const [mobileHide, setMobileHide] = useState(false);
  const mailRef = useRef<null | HTMLDivElement>(null);

  const onScrollTop = () => {
    window.scrollTo({ behavior: 'smooth', top: 0 });
  };

  const onSetMobileHide = () => {
    const newScroll = window.pageYOffset;
    setMobileHide(newScroll <= 0);
  };

  useEffect(() => {
    onSetMobileHide();
    window.addEventListener('scroll', onSetMobileHide);
    return () => window.removeEventListener('scroll', onSetMobileHide);
  }, []);

  useEffect(() => {
    const { hash } = window.location;
    if (hash !== '#footer-mailing') { return; }
    setTimeout(() => {
      mailRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 500);
  }, []);

  return (
    <div className={c.footer + (className ? ` ${className}` : '')} id="footer">
      <div
        role="button"
        aria-hidden
        className={c.footer_logo}
        onClick={onScrollTop}
      >
        <LogoPage />
      </div>
      <div className={c.footer_bord} />
      <div
        className={`${c.container} ${c[`mobileHide-${mobileHide}`]}`}
        id="footer-mailing"
        ref={mailRef}
      >
        <div className={c.wrap}>
          <div className={c.title}>
            <ServiceText name="mailing" />
          </div>
          <FooterSubscription />
          <div className={c.terms}>
            <div className={c.terms_poly}>
              <ServiceText name="legal_info" />
              <br />
              <Link to="/privacy_policy">Пользовательское соглашение.</Link>
            </div>
          </div>
          <div className={c.down}>
            <div className={c.down_copy}>
              © Reminder
              {new Date().getFullYear()}
            </div>
            <Link className={c.down_rss} target="_blank" to="/rss"><span>RSS</span></Link>
            <ItSummaLogo />
          </div>

        </div>
      </div>
    </div>
  );
};

export default FooterPage;
