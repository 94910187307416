import React, { FunctionComponent } from 'react';
import c from '~/components/post/ContentPost.sass';
import ServiceText from '~/components/ServiceText';
import { Page } from '~/components';
import config from '~/config';
import Banner from '~/components/Banner';
import Helmeter from './Helmeter';
import { useResetScroll } from './screen';

const AboutScreen: FunctionComponent = () => {
  useResetScroll();

  // const { pathname } = useLocation();
  // const ampLink = `${config('APP_HOST')}/amp${pathname}`;
  const canonicalLink = `${config('APP_HOST')}/about`;

  return (
    <>
      <Banner />
      <Page>
        <Helmeter
          title="О проекте - Reminder"
          metaTitle="О проекте - Reminder"
          link={[
            // { rel: 'amphtml', href: ampLink },
            { rel: 'canonical', href: canonicalLink },
          ]}
        />
        <div className={c.container}>
          <div className={c.wrap}>
            <ServiceText name="about_project" />
          </div>
        </div>
      </Page>
    </>
  );
};

export default AboutScreen;
