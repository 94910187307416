import React, { FunctionComponent, useEffect } from 'react';

import {
  Page, Paginator, Loader, ReportsList,
} from '~/components';
import { useConfig, FetchStatus } from '~/resources';
import Helmeter from './Helmeter';
import config from '~/config';
import { useResetScroll } from './screen';
import Banner from '~/components/Banner';

const ReportsScreen: FunctionComponent = () => {
  useResetScroll();

  const { status, fetchBy } = useConfig();
  useEffect(() => {
    fetchBy();
  }, []);

  // if (status !== FetchStatus.Fetched) {
  //   return <Loader />;
  // }
  return (
    <>
      <Banner />
      <Page>
        <Helmeter
          description="Отчеты Reminder — это сборники материалов, в которых глубоко исследуется тема и даются практические советы."
          metaDescription="Отчеты Reminder — это сборники материалов, в которых глубоко исследуется тема и даются практические советы."
          img={`${config('APP_HOST')}/public/reports_og.png`}
        />
        <Paginator>
          {(page, handleMeta, handleLoading) => (
            <ReportsList
              page={page}
              onMeta={handleMeta}
              onLoading={handleLoading}
            />
          )}
        </Paginator>
      </Page>
    </>
  );
};

export default ReportsScreen;
