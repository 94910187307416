import React, { FunctionComponent, useEffect } from 'react';
import useSubscriptionReviews from '~/resources/useSubscriptionReviews';
import Loader from '../Loader';
import c from './PaidSubscriptionReviews.sass';

const PaidSubscriptionReviews: FunctionComponent = () => {
  const { data: reviews, fetchBy } = useSubscriptionReviews();

  useEffect(() => {
    fetchBy();
  }, []);

  if (!reviews) {
    return (<Loader />);
  }

  const renderReviews = () => {
    if (!reviews?.length) {
      return null;
    }
    return reviews.map((review) => (
      <div key={review.id} className={c.reviews__block}>
        <div className={c.reviews__block_text}>
          –
          {' '}
          { review.text }
        </div>
        <div className={c.reviews__block_author}>{ review.name }</div>
        <div className={c.reviews__block_position}>{ review.position }</div>
      </div>
    ));
  };

  return (
    <div className={c.reviews}>
      <div className={c.title}>Что говорят читатели</div>
      <div className={c.reviews__list}>
        { renderReviews() }
      </div>
    </div>
  );
};

export default PaidSubscriptionReviews;
