import queryString from 'query-string';

import useResource, { ResourceResult, Resource, QueryParams } from './useResource';
import { Tag } from './models';
import config from '~/config';
import anyFetch from './anyFetch';
import Auth from './Auth';

const PATH = () => `${config('API_HOST')}/tags`;
const CACHE_KEY_PREFIX = 'tags';

export const tagsFetcher = async (
  params: QueryParams = {},
  opts: RequestInit = {},
): Promise<Resource<Tag[]>> => {
  let path = PATH();
  const fillParams = {
    ...params,
  };
  const paramsStr = queryString.stringify(fillParams, {
    arrayFormat: 'bracket',
  });

  if (paramsStr) {
    path += `?${paramsStr}`;
  }
  const res = await anyFetch(path, opts);
  const data = await res.json();
  return data;
};

const useTags = (): ResourceResult<Tag[]> => useResource({}, {}, tagsFetcher, CACHE_KEY_PREFIX);

export default useTags;

export const fetchSSRTags = async (auth: Auth, opts: RequestInit = {}): Promise<any> => {
  const res = await tagsFetcher({}, opts);
  auth.setCache(CACHE_KEY_PREFIX, res);
};
