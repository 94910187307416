import React, { FunctionComponent } from 'react';
import { SubscriptionPlan } from '~/resources/models';
import c from './PaidSubscriptionForm.sass';
import PaywallPlanItem from '../Paywall/PaywallPlanItem';

export interface SubscriptionFormProps {
  plans: SubscriptionPlan[];
  onSubmit: (plan: SubscriptionPlan) => void;
}

export const SubscriptionForm: FunctionComponent<SubscriptionFormProps> = ({ plans, onSubmit }) => {
  const getPlanItems = () => plans?.sort((a, b) => {
    if (a.type < b.type) { return 1; }
    if (a.type > b.type) { return -1; }
    return 0;
  }).map((_plan) => (<PaywallPlanItem key={`plan_${_plan.fake_id}`} plan={_plan} onClick={onSubmit} />));

  return plans && plans.length > 0 ? (
    <div className={c.plans_wrapper}>
      {getPlanItems()}
    </div>
  ) : null;
};
