import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { History } from 'history';

import c from './SearchInput.sass';
import { pluralize } from '~/utils';

interface Props {
    loading: boolean;
    length: number;
    queryInit: string;
    setSearch: (query: string) => void;
}

/* const getQueryURL = () => {
  let query = '';
  if (typeof window !== 'undefined') {
    const url = new URL(window.location.href);
    query = url.searchParams.get('search') || '';
  }
  return query;
}; */

const setQueryURL = (query: string, history: History<{ search: string }>) => {
  if (typeof window !== 'undefined') {
    const url = new URL(window.location.href);
    if (query) {
      url.searchParams.set('search', query);
    } else {
      url.searchParams.delete('search');
    }
    history.replace({
      search: url.search,
    });
  }
};

const SearchInput: FunctionComponent<Props> = ({
  loading, length = 0, queryInit, setSearch,
}) => {
  const history = useHistory<{ search: string }>();
  const [query, setQuery] = useState(queryInit);
  const [noFind, setNoFind] = useState(() => false);

  useEffect(() => {
    if (query) {
      setSearch(query);
    }
  }, []);

  useEffect(() => {
    setQueryURL(query, history);
  }, [query]);

  useEffect(() => {
    if (query) {
      setNoFind(length === 0);
    }
  }, [loading, length]);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (setSearch && !loading) {
      setSearch(query);
    }
  };

  return (
    <div className={c.search + (loading ? ` ${c.loading}` : '') + ((length > 0 && query) ? ` ${c.exist}` : '')}>
      <form className={c.line} onSubmit={onSubmit}>
        <input
          className={c.input}
          value={query}
          onChange={({ currentTarget }) => setQuery(currentTarget.value)}
          placeholder="Начните писать"
          disabled={loading}
          required
        />
        <button type="submit" className={c.icon} aria-label="Начать поиск">
          <svg viewBox="0 0 37 37">
            <path d="M 18.500003,0 A 18.5,18.5 0 0 0 0,18.5 18.5,18.5 0 0 0 18.500003,37 18.5,18.5 0 0 0 30.449222,32.597657 l -5.798828,-5.837891 c -4.0696,2.8973 -9.747852,2.531459 -13.398442,-1.119141 -4.069644,-4.0696 -4.069644,-10.668651 0,-14.738281 4.06963,-4.06964 10.668686,-4.06964 14.738286,0 3.6506,3.65059 4.01644,9.328191 1.11914,13.400391 l 5.771485,5.810547 A 18.5,18.5 0 0 0 37.000003,18.5 18.5,18.5 0 0 0 18.500003,0 Z m 0.123047,11.328125 c -1.779018,3.22e-4 -3.559228,0.677705 -4.914058,2.03125 -2.70966,2.70709 -2.70966,7.116472 0,9.826172 2.70966,2.7097 7.116468,2.7097 9.826168,0 2.709699,-2.7097 2.709699,-7.116512 0,-9.826172 -1.35485,-1.35483 -3.133092,-2.031571 -4.91211,-2.03125 z" />
          </svg>
        </button>
      </form>
      {(length > 0) && (
        <div className={c.line}>
          <div className={c.title}>Найдено</div>
          <div className={c.value}>
            <span>{length}</span>
            {' '}
            { pluralize('материал', length, ['', 'а', 'ов']) }
          </div>
        </div>
      )}
      {(!loading && noFind) && (
        <div className={c.line}>
          <div className={c.title}>Ничего не найдено</div>
        </div>
      )}
    </div>
  );
};

SearchInput.displayName = 'SearchInput';

export default SearchInput;
