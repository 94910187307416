import React, { FunctionComponent, Fragment, useEffect } from 'react';
import { Redirect } from 'react-router';
import usePosts from '~/resources/usePosts';
import { Loader } from '~/components';
import PreviewPost from './PreviewPost';
import { ResourceMeta, QueryParams, useConfig } from '~/resources';
import { PreviewSuper } from '../super';
import ItemList from '../ItemList/ItemList';
import Helmeter from '~/screens/Helmeter';
import { isResourceFetchError } from '../Subscription/utils';

interface Props {
  slug: string;
  page: number;
  onMeta: (meta: ResourceMeta) => void,
  onLoading: (loading: boolean) => void,
}

const TopicPostList: FunctionComponent<Props> = ({
  page, onMeta, onLoading, slug,
}) => {
  const params: QueryParams = { type: 'article', page: String(page) };
  params.topic_id = slug;
  const { data: config } = useConfig();
  const topic = config?.topics.find((item) => item.slug === slug);

  const {
    meta, fetchBy: fetchPostsBy, data: posts, status,
  } = usePosts(params);

  useEffect(() => {
    fetchPostsBy();
  }, [page, slug]);

  useEffect(() => {
    if (meta) {
      onMeta(meta);
    }
  }, [meta]);

  useEffect(() => {
    onLoading(!posts);
  }, [posts]);

  const items = () => {
    const items = new Array();

    if (!posts) return items;

    posts.forEach((post) => {
      if (post.preview_mode === 'preview_super') {
        items.push(<PreviewSuper post={post} />);
      } else {
        items.push(<PreviewPost post={post} />);
      }
    });

    return items;
  }

  if (!posts) {
    return isResourceFetchError(posts, status) ? <Redirect to="/404" /> : <Loader />;
  }

  return (
    <>
      <Helmeter
        metaTitle={`${topic?.title} - Reminder`}
      />
      <ItemList items={items()} />
    </>
  );
};

export default TopicPostList;
