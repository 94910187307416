import React, { FunctionComponent } from 'react';
import { useLocation } from 'react-router';

import { NewsLettersPage } from '~/components';
import Helmeter from './Helmeter';

const NewsLettersScreen: FunctionComponent = () => {
  const query = new URLSearchParams(useLocation().search);
  const referrer = query.get('email');

  return (
    <>
      <Helmeter
        title="Подписка - Reminder"
        metaTitle="Подписка - Reminder"
      />
      <NewsLettersPage referrer={referrer || null} />
    </>
  );
};

export default NewsLettersScreen;
