import React, { FunctionComponent } from 'react';

import c from './More.sass';

interface MoreProps extends React.HTMLAttributes<HTMLAnchorElement> {
    url: string,
    onClick: () => void,
}

const More: FunctionComponent<MoreProps> = ({
  className,
  url,
  onClick,
}) => (
  <a
    className={c.more + (className ? ` ${className}` : '')}
    href={url}
    onClick={(e) => { e.preventDefault(); onClick(); }}
  >
    <div className={c.morePurple} />
    <span>Еще немного</span>
  </a>
);

More.displayName = 'More';

export default More;
