import React, { FunctionComponent } from 'react';

const MarkCheck: FunctionComponent = () => (

  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M5.89242 14.9342C5.89269 14.9344 5.89296 14.9347 5.89323 14.935L6.66479 15.7065M5.89242 14.9342L1.23796 10.2797C0.847438 9.88917 0.847437 9.25601 1.23796 8.86548L2.00951 8.09393C2.40004 7.7034 3.0332 7.7034 3.42373 8.09393L7.3719 12.0421L14.5762 4.83782C14.9667 4.44729 15.5999 4.44729 15.9904 4.83782L16.7619 5.60937C17.1525 5.99989 17.1525 6.63306 16.7619 7.02358L8.85852 14.927C8.8559 14.9297 8.85326 14.9324 8.8506 14.935L8.07905 15.7066C7.71752 16.0681 7.14805 16.0949 6.75564 15.7871C6.73077 15.7676 6.70662 15.7467 6.68327 15.7245" fill="#F85B2E" />
  </svg>

);

export default MarkCheck;
