import React, { FunctionComponent } from 'react';

import c from './BurgerPage.sass';

interface Props {
    isCross?: boolean
}

const BurgerPage: FunctionComponent<Props> = ({
  isCross = false,
}) => (
  <div className={c.burger + (isCross ? ` ${c.burger_isCross}` : '')}>
    <div className={c.burger_line} />
    <div className={c.burger_line} />
  </div>
);

export default BurgerPage;
