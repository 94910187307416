import React, { FunctionComponent, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import useSubscription from '~/resources/useSubscription';
import { SubscriptionPlan } from '~/resources/models';
import useSubscriptionFlow from '~/resources/useSubscriptionFlow';
import { SubscriptionStatus } from '~/utils/purshase-event';
import useProfile from '~/resources/useProfile';
import disableScroll from '~/utils/disableScroll';
import { AlertModalType, AuthModalFrom, AuthModalState, usePageContext } from '../page/context.page';
import { SubscriptionFlowStep, SubscriptionType } from '../page/context.subscription-flow';

import c from './Paywall.sass';
import Loader from '../Loader';
import PaywallHeader from './PaywallHeader';
import PaywallPlanItem from './PaywallPlanItem';
import { SubscriptionCheck } from '../PaidSubscription/SubscriptionCheck';
import { SubscriptionPayment } from '../PaidSubscription/SubscriptionPayment';
import { SubscriptionStatusModal } from '../PaidSubscription/SubscriptionStatusModal';

export interface PaywallProps {
  isReady?: boolean;
  postSlug?: string;
}

const Paywall: FunctionComponent<PaywallProps> = ({ isReady, postSlug }) => {
  const { url } = useRouteMatch();
  const {
    authModal, profile, auth, alertModal,
  } = usePageContext();
  const { fetchBy: fetchPlans, data: plans } = useSubscription();
  const {
    buySubscription,
    subscriptionFlowVal,
    cancelSubscription,
    checkPayment,
  } = useSubscriptionFlow();
  const [finalStatus, setFinalStatus] = useState<SubscriptionStatus>();
  const step = subscriptionFlowVal && subscriptionFlowVal.step;
  const plan = subscriptionFlowVal && subscriptionFlowVal.plan;
  const [orderId, setOrderId] = useState<number>();
  const { data: profileData, fetchBy: fetchProfile } = useProfile();

  useEffect(() => {
    fetchPlans();
  }, []);

  useEffect(() => {
    if (!profileData || !profile || !profile[1]) { return; }
    if (alertModal) {
      alertModal[1]({ alertType: AlertModalType.NONE });
    }
    disableScroll(false);
    profile[1](profileData);
  }, [profileData]);

  const onAlreadySub = () => {
    if (authModal) {
      authModal[1]({ authModalState: AuthModalState.CHECK, authModalFrom: AuthModalFrom.NONE });
    }
    disableScroll(true);
  };

  const buy = (selectedPlan: SubscriptionPlan) => {
    if (!selectedPlan) { return; }
    buySubscription(selectedPlan, SubscriptionType.NEW, url);
  };

  const getPlanItems = () => plans?.sort((a, b) => {
    if (a.type < b.type) { return 1; }
    if (a.type > b.type) { return -1; }
    return 0;
  }).map((_plan) => (<PaywallPlanItem key={`plan_${_plan.fake_id}`} plan={_plan} onClick={buy} />));

  const onStatus = (status: SubscriptionStatus) => {
    setFinalStatus(status);
    cancelSubscription();
  };

  const onSuccessPayment = (oId: number) => {
    setOrderId(oId);
    checkPayment();
  };

  const cancel = () => {
    if (
      finalStatus === SubscriptionStatus.PAYMENT_CHECK_PROCESSED
      || finalStatus === SubscriptionStatus.ALREADY_SUBSCRIBED
    ) {
      fetchProfile();
      auth?.clearCache();
      window.scrollTo({ behavior: 'smooth', top: 0 });
    }
    setFinalStatus(undefined);
    cancelSubscription();
  };

  const renderPayment = () => (step === SubscriptionFlowStep.PAYMENT && plan
    ? (
      <SubscriptionPayment
        plan={plan}
        promocode=""
        onError={onStatus}
        onSuccess={onSuccessPayment}
        onClose={cancel}
        postSlug={postSlug}
      />
    )
    : null
  );

  const renderCheck = () => (step === SubscriptionFlowStep.CHECK && orderId && (
    <SubscriptionCheck
      orderId={orderId}
      onClose={cancel}
      onCheck={onStatus}
    />
  ));

  const renderStatus = () => (finalStatus && (
    <SubscriptionStatusModal status={finalStatus} onClose={cancel} />
  ));

  return (
    <div className={c.alertwrapper}>
      <div className={c.gradient} />
      {
          isReady ? (
            <>
              <div className={c.paywall_wrapper}>
                <PaywallHeader
                  onClick={onAlreadySub}
                  isProfie={!!(profile && profile[0])}
                />
                <div className={c.plans_wrapper}>
                  {getPlanItems()}
                </div>
              </div>
              {renderPayment()}
              {renderCheck()}
              {renderStatus()}
            </>
          ) : (<Loader />)
        }
    </div>
  );
};

Paywall.displayName = 'Paywall';
Paywall.defaultProps = {
  isReady: false,
  postSlug: undefined,
};

export default Paywall;
