/* eslint-disable camelcase */
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import { useConfig } from '~/resources';
import Image from '../Image';
import Topic from '../Topic';
import c from './PreviewPost.sass';
import { Post } from '~/resources/models';
import Rubric from '../Rubric';

type Props = {
    post: Post;
    showRubricLabel?: boolean;
}

const PreviewPost: FunctionComponent<Props> = ({
  post, showRubricLabel = true
}) => {
  const {
    topic, published_at, title, description,
    image_preview, preview_mode = 'preview', slug, rubric
  } = post;
  const { data: config } = useConfig();
  const imageConfig = config ? config.images : {};

  const urlPost = `/post/${slug}`;
  const urlTopic = topic && `/topic/${post.topic.slug}`;
  const urlRubric = rubric && `/rubric/${rubric.slug}`;

  return (
    <div className={`${c.container} ${c[`container_${preview_mode}`]}`}>
      <div className={c.content}>
        {(topic && topic.title) && (
        <div className={c.top}>
          <Topic
            title={topic.title}
            subtitle={new Date(+published_at * 1000).toLocaleDateString('ru')}
            href={urlTopic}
            colorOrange={!!post.partner_material}
          />
          {showRubricLabel && rubric && <Rubric name={rubric.name} href={urlRubric}/>}
        </div>
        )}
        <Link to={urlPost} className={c.title}>{title}</Link>
        {description && (
        <div className={c.description}>{description}</div>
        )}
      </div>
      <div className={c.imageWrap}>
        {description && (
        <div className={`${c.description} ${c.description_mob}`}>{description}</div>
        )}
        {image_preview && (
          <Link to={urlPost}>
            <Image
              {...image_preview}
              mode={preview_mode}
              className={c[`place-${preview_mode}`]}
              imageConfig={imageConfig}
              contentEditable={undefined}
              alt={image_preview.alt || title}
            />
          </Link>
        )}
      </div>
    </div>
  );
};

PreviewPost.displayName = 'Post';

export default PreviewPost;
