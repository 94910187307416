import React, { useEffect, useState, forwardRef } from 'react';
import classes from './BuyButton.sass';

interface Props {
  style?: any;
  ref: any;
  hide?: boolean;
  disabled?: boolean
  price: number;
  caption: string;
}

const BuyButton = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const {
    style, price, caption, hide, disabled = false
  } = props;

  return (
    <button
      className={
        `${classes.buyButton}${(hide) ? ` ${classes.buyButton_hide}` : ''}`
      }
      style={style}
      type="button"
      ref={ref || undefined}
      disabled={disabled}
    >
      <h3>
        {price ? `Купить за ${price}` : 'Получить'}
        {!!price && (<span style={{ fontFamily: '-apple-system, BlinkMacSystemFont, sans-serif' }}>&#8381;</span>)}
      </h3>
      <p className={classes.buyButton__text}>
        {caption}
      </p>
    </button>
  );
});

export default BuyButton;
