/* eslint-disable camelcase */
import React, { useState, useEffect, FunctionComponent } from 'react';
import { useLocation } from 'react-router';
import { Helmet } from 'react-helmet';

import config from '~/config';
import { Post } from '~/resources/models';

interface Props {
  post: Post;
}

const generateData = (post: Post, location: any) => {
  const url = `${config('APP_HOST')}${location.pathname}`;
  const siteName = 'Reminder';
  const logoSrc = `${config('APP_HOST')}/public/logo-white.png`;
  let body: any = { '@context': 'http://schema.org' };
  const {
    image, published_at, updated_at, title, description, id, authors, topic,
  } = post;
  body = {
    ...body,
    '@type': 'Article',
    url,
    headline: title,
    name: title,
    identifier: String(id),
    creator: siteName,
    publisher: {
      '@type': 'Organization',
      name: siteName,
      url: config('APP_HOST'),
      logo: {
        type: 'ImageObject',
        url: logoSrc,
      },
    },
    mainEntityOfPage: url,
    dateModified: new Date(+updated_at * 1000),
  };

  if (image?.src) body = { ...body, image: [image.src] };
  if (published_at) body = { ...body, datePublished: new Date(+published_at * 1000) };
  if (description) body = { ...body, description };
  if (authors[0]?.name) {
    body = {
      ...body,
      keywords: authors.map(({ name }) => `Tag:${name}`),
      author: { '@type': 'Person', name: authors[0].name },
    };
  }
  if (topic?.slug) {
    const topicKey = `Topic:${topic?.slug}`;

    if (body?.keywords) {
      body = { ...body, keywords: [...body.keywords, topicKey] };
    } else {
      body = { ...body, keywords: [topicKey] };
    }
  }
  return body;
};

const JsonLdPost: FunctionComponent<Props> = ({ post }) => {
  const location = useLocation();
  const [dataContent, setDataContent] = useState(generateData(post, location));

  useEffect(() => {
    setDataContent(generateData(post, location));
  }, [location, post]);

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(dataContent)}
      </script>
    </Helmet>
  );
};

export default JsonLdPost;
