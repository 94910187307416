import React, { FunctionComponent, useEffect } from 'react';
import { Redirect } from 'react-router';
import usePosts from '~/resources/usePosts';
import { Loader } from '~/components';
import PreviewPost from './PreviewPost';
import { ResourceMeta, QueryParams } from '~/resources';
import { PreviewSuper } from '../super';
import useRubric from '~/resources/useRubric';
import c from './PostList.sass'
import Helmeter from '~/screens/Helmeter';
import ItemList from '../ItemList/ItemList';
import { isResourceFetchError } from '../Subscription/utils';

interface Props {
  slug: string;
  page: number;
  onMeta: (meta: ResourceMeta) => void,
  onLoading: (loading: boolean) => void,
}

const RubricPostList: FunctionComponent<Props> = ({
  page, onMeta, onLoading, slug,
}) => {
  const params: QueryParams = { type: 'article', page: String(page) };
  params.rubric_id = slug;

  const {
    meta, fetchBy: fetchPostsBy, data: posts, status,
  } = usePosts(params);

  const {
    fetchBy: fetchRubricBy, data: rubric
  } = useRubric(slug);

  useEffect(() => {
    fetchRubricBy();
  }, [])

  useEffect(() => {
    fetchPostsBy();
  }, [page]);

  useEffect(() => {
    if (meta) {
      onMeta(meta);
    }
  }, [meta]);

  useEffect(() => {
    onLoading(!posts);
  }, [posts]);

  const items = () => {
    const items = new Array();
    if (page === 1) {
      items.push(<div className={c.rubric_name}>{rubric?.name || ''}</div>);
    }

    if (!posts) return items;

    posts.forEach((post) => {
      if (post.preview_mode === 'preview_super') {
        items.push(<PreviewSuper post={post} showRubricLabel={false}/>);
      } else {
        items.push(<PreviewPost post={post} showRubricLabel={false}/>);
      }
    });

    return items;
  }

  if (!posts) {
    return isResourceFetchError(posts, status) ? <Redirect to="/404" /> : <Loader />;
  }

  return (
    <>
      <Helmeter
        metaTitle={rubric?.meta_title ? `${rubric.meta_title} - Reminder` : `${rubric?.name} - Reminder`}
        metaDescription={rubric?.meta_description}
      />
      <ItemList items={items()} />
    </>
  );
};

export default RubricPostList;
