import useResource, { ResourceResult, Resource } from './useResource';
import { Rubric } from './models';

import config from '../config';
import anyFetch from './anyFetch';
import Auth from './Auth';
import { match as Match } from 'react-router';

const PATH = () => `${config('API_HOST')}/rubrics`;
const CACHE_KEY_PREFIX = 'rubrics/';

const rubricFetcher = (slug: string) => async (params = {}, opts = {}): Promise<Resource<Rubric>> => {
  const res = await anyFetch(`${PATH()}/${slug}`, opts);
  const data = await res.json();
  return data;
};

const useRubric = (slug: string): ResourceResult<Rubric> =>
  useResource({}, {}, rubricFetcher(slug), `${CACHE_KEY_PREFIX}${slug}`);

export const fetchSSRRubric = async (
  auth: Auth,
  path: string,
  match: Match,
  opts: RequestInit = {},
): Promise<any> => {
  const {rubric} = (match as Match<{rubric: string}>).params;
  const res = await rubricFetcher(rubric)({}, opts);
  auth.setCache(`${CACHE_KEY_PREFIX}${rubric}`, res);
};

export default useRubric;
