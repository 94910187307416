/* eslint-disable camelcase */
import React, {
  FunctionComponent, MutableRefObject, useEffect, useRef, useState,
} from 'react';
import { HeaderPage, Topic } from '~/components';
import ReportPrice from './ReportPrice';
import classes from './ReportHeader.sass';
import imgNoise from '~/images/noise-bg-report.png';
import { Report } from '~/resources/models';
import { useFormContext } from './Form';

interface Props {
  report: Report;
  gradient: string;
}

const ReportHeader: FunctionComponent<Props> = ({ report, gradient }) => {
  const {
    title, active_price, pre_order_price, cover_origin, status,
  } = report;

  const [, setOrderingReport] = useFormContext().orderingReport;

  const headerBuyButtonRef = useRef<HTMLButtonElement>() as MutableRefObject<HTMLButtonElement>;

  const [screenSmDown, setScreenSmDown] = useState(false);

  useEffect(() => {
    if (typeof window === 'undefined') return undefined;

    const onResize = () => setScreenSmDown(window.innerWidth < 768);
    onResize();
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  const price = (() => {
    if (status === 'pre_order' && pre_order_price) return pre_order_price;
    if (status === 'active' && active_price) return active_price;
    return 0;
  })();

  return (
    <div className={classes.reportHeader} style={{ background: gradient }}>

      <img className={classes.reportHeader__noise} src={imgNoise} alt="" />

      <div style={{ opacity: 0 }}><HeaderPage closeOverlay /></div>

      <div className={classes.reportHeader__content}>
        <div className={classes.reportHeader__hr} />

        <div className={classes.reportHeader__descriptionBlockWrapper}>
          <div>
            <Topic title="отчеты" href="/reports" />
            <h1 className={classes.reportHeader__title}>{title}</h1>
          </div>
          <div>
            <div className={classes.reportHeader__price} />
            <ReportPrice
              {...{
                active_price,
                pre_order_price,
                status,
              }}
              color="rgb(255, 255, 255)"
            />
            {!screenSmDown && (
            <button
              className={classes.reportHeader__button}
              ref={headerBuyButtonRef}
              type="button"
              onClick={() => { setOrderingReport(report); }}
            >
              {price ? 'Купить' : 'Получить'}
            </button>
            )}
            {!screenSmDown && (
            <p className={classes.reportHeader__date}>
              {report.caption}
            </p>
            )}
          </div>
        </div>

        <img className={classes.reportHeader__img} src={cover_origin.src} alt="" />
      </div>
    </div>
  );
};

export default ReportHeader;
