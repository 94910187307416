import React, { FunctionComponent, useEffect, useRef } from 'react';

interface Props {
    name: string
    current: { name: string, path: string }
    last: { name: string, path: string }
}

export const useResetScroll = (...deps) => {
  useEffect(() => {
    const t = setTimeout(() => window.scroll({ top: 0 }), 16);
    return () => clearTimeout(t);
  }, [...deps]);
};

export const useScreenTitle = (...deps) => {
  const onChange = () => {
    const selectors = ['h1', 'h2', 'h3'];
    if (document.location.pathname !== '/') {
      selectors.push(`a[href="${document.location.pathname}"]`);
    }
    const titElem = document.querySelector(selectors.join(', '));
    let title = 'Reminder';
    if (titElem) {
      title = `${titElem.textContent} - ${title}`;
    }
    if (document.title !== title) {
      document.title = title;
    }
  };

  useEffect(() => {
    const t = setTimeout(() => {
      onChange();
    }, 16);
    return () => clearTimeout(t);
  }, [...deps]);
};

export const Screen: FunctionComponent<Props> = ({
  children, name, current, last,
}) => {
  const ref = useRef<HTMLDivElement>();

  useEffect(() => {
    if (current.name === name) {
      onShow();
    } else if (last.name === name) {
      onHide();
    }
  }, [current.path, last.path]);

  const onShow = () => {
    ref.current.style.display = null;
  };

  const onHide = () => {
    ref.current.style.display = 'none';
  };

  const isRender = current.name === name || last.name === name;

  return (
    <div ref={ref}>
      {(isRender) && (
        children
      )}
    </div>
  );
};

Screen.displayName = 'Screen';
