import React, { FunctionComponent, useEffect } from 'react';
import { Redirect } from 'react-router';
import usePosts from '~/resources/usePosts';
import { Loader } from '~/components';
import PreviewPost from './PreviewPost';
import { ResourceMeta, QueryParams } from '~/resources';
import { PreviewSuper } from '../super';
import useTag from '~/resources/useTag';
import c from './PostList.sass';
import Helmeter from '~/screens/Helmeter';
import ItemList from '../ItemList/ItemList';
import { isResourceFetchError } from '../Subscription/utils';
import PreviewLongreadPost from './PreviewLongreadPost';

interface Props {
  slug: string;
  page: number;
  onMeta: (meta: ResourceMeta) => void,
  onLoading: (loading: boolean) => void,
}

const TagPostList: FunctionComponent<Props> = ({
  page, onMeta, onLoading, slug,
}) => {
  const params: QueryParams = { page: String(page) };
  params.tag_id = slug;

  const {
    meta, fetchBy: fetchPostsBy, data: posts, status,
  } = usePosts(params);

  const {
    fetchBy: fetchTagBy, data: tag,
  } = useTag(slug);

  useEffect(() => {
    fetchTagBy();
  }, []);

  useEffect(() => {
    fetchPostsBy();
  }, [page]);

  useEffect(() => {
    if (meta) {
      onMeta(meta);
    }
  }, [meta]);

  useEffect(() => {
    onLoading(!posts);
  }, [posts]);

  const items = () => {
    const items = [];
    if (page === 1) {
      items.push(<div className={c.tag_name}>{tag?.name || ''}</div>);
    }

    if (!posts) return items;

    posts.forEach((post) => {
      switch (post.preview_mode) {
        case 'preview_super': {
          items.push(<PreviewSuper post={post} showRubricLabel={false} />);
          break;
        }
        case 'preview_longread': {
          items.push(<PreviewLongreadPost post={post} />);
          break;
        }
        default: {
          items.push(<PreviewPost post={post} showRubricLabel={false} />);
          break;
        }
      }
    });

    return items;
  };

  if (!posts) {
    return isResourceFetchError(posts, status) ? <Redirect to="/404" /> : <Loader />;
  }

  return (
    <>
      <Helmeter
        metaTitle={tag?.meta_title ? `${tag.meta_title} - Reminder` : `${tag?.name} - Reminder`}
        metaDescription={tag?.meta_description}
      />
      <ItemList items={items()} />
    </>
  );
};

export default TagPostList;
