import React, { FunctionComponent, useEffect } from 'react';
import { GiftPage, Page } from '~/components';
import config from '~/config';
import Banner from '~/components/Banner';
import Helmeter from './Helmeter';

const GiftScreen: FunctionComponent = () => {
  // const history = useHistory();
  // const { profile, subscriptionFlow } = usePageContext();
  // const { data } = useConfig();

  useEffect(() => {
    window.scroll({ top: 0 });
  });

  // const { pathname } = useLocation();
  // const ampLink = `${config('APP_HOST')}/amp${pathname}`;
  // const canonicalLink = `${config('APP_HOST')}/gift`;
  const ogImage = `${config('APP_HOST')}/public/subscription/ogGift.jpg`;

  return (
    <>
      <Banner />
      <Page>
        <Helmeter
          title="Подарочная подписка"
          metaTitle="Подарочная подписка"
          metaDescription="Подписка на лучшее знание о здоровье и саморазвитии"
          img={ogImage}
          link={[
            // { rel: 'amphtml', href: ampLink },
            // { rel: 'canonical', href: canonicalLink },
          ]}
          srcScripts={['https://yookassa.ru/checkout-widget/v1/checkout-widget.js']}
        />
        <GiftPage />
      </Page>
    </>
  );
};

export default GiftScreen;
