import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import c from './Rubric.sass';

interface Props {
    name: string;
    href: string;
}

const Rubric: FunctionComponent<Props> = ({ name, href }) => (
    <Link to={href} className={`${c.rubric} ${c.rubric__name}`}>{name}</Link>
);

export default Rubric;
