import React, { FC } from 'react';
import { SubscriptionPlan } from '~/resources/models';
import numberWithSpaces from '~/utils/price';
import c from './Paywall.sass';
import MarkCheck from './MarkCheck';
import ReminderLogo from './ReminderLogo';

export interface PaywallProps {
  plan: SubscriptionPlan,
  onClick: (plan: SubscriptionPlan)=>void,
}

const benefits = [
  {
    content: (
      <li key="benefit_1">
        <MarkCheck />
        <p>
          <b>Все статьи </b>
          на сайте
        </p>
      </li>),
    notFor: null,
  },
  {
    content: (
      <li key="benefit_2">
        <MarkCheck />
        <p>
          <b>3 встречи </b>
          с экспертами в&nbsp;месяц
        </p>
      </li>),
    notFor: 'annual',
  },
  {
    content: (
      <li key="benefit_3">
        <MarkCheck />
        <p>
          <b>40+ встреч </b>
          с экспертами в&nbsp;год
        </p>
      </li>),
    notFor: 'monthly',
  },
  {
    content: (
      <li key="benefit_4">
        <MarkCheck />
        <p>
          <b>Общение </b>
          с другими подписчиками
        </p>
      </li>),
    notFor: null,
  },
  {
    content: (
      <li key="benefit_5">
        <MarkCheck />
        <p>
          <b>Полная версия </b>
          рассылки по&nbsp;вторникам
        </p>
      </li>),
    notFor: null,
  },
  {
    content: (
      <li key="benefit_6">
        <ReminderLogo />
        <p>
          <span>Подарок: </span>
          все мини-книги Reminder
        </p>
      </li>),
    notFor: 'monthly',
  },
];

const PaywallPlanItem: FC<PaywallProps> = ({ plan, onClick }) => {
  const getBenefits = () => benefits.map((benefit) => {
    if (benefit.notFor && benefit.notFor === plan.type) {
      return null;
    }
    return benefit.content;
  });

  const procent = plan.full_price
    ? Math.round(100 - ((plan.price * 100) / plan.full_price))
    : null;

  return (
    <div className={c.plan_item}>
      { procent && (
        <div className={c.plan_discount_wrapper}>
          <div className={c.plan_discount}>
            -
            {procent}
            %
          </div>
        </div>
      )}
      <div className={c.plan_title}>
        { plan.type === 'monthly' ? 'Месяц — ' : 'Год — ' }
        {numberWithSpaces(plan.price / 100)}
        { ' ₽ ' }
        {plan.full_price && (
          <s className={c.plan_title_fullprice}>
            {numberWithSpaces(plan.full_price / 100)}
            { ' ₽' }
          </s>
        )}
      </div>
      <button
        className={c.plan_button}
        type="button"
        onClick={() => onClick(plan)}
      >
        Подписаться
      </button>
      <div className={c.plan_description}>
        Продлевается автоматически раз в
        { ' ' }
        { plan.type === 'monthly' ? 'месяц' : 'год' }
        . Легко&nbsp;отменить
      </div>
      <ul className={c.plan_benefits}>{getBenefits()}</ul>
    </div>
  );
};

PaywallPlanItem.displayName = 'PaywallPlanItem';

export default PaywallPlanItem;
