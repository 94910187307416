import config from '~/config';
import queryString from 'query-string';
import useResource, { ResourceResult, Resource, QueryParams } from './useResource';
import { SubscriptionPlan } from './models';
import anyFetch from './anyFetch';
import Auth from './Auth';

const PATH = () => `${config('API_HOST')}/subscription_gift_plan`;

export const subscriptionFetcher = async (
  params: QueryParams = {},
  opts: RequestInit = {},
): Promise<Resource<SubscriptionPlan>> => {
  let path = PATH();
  const paramsStr = queryString.stringify(params, {
    // arrayFormat: 'bracket',
  });
  if (paramsStr) {
    path += `?${paramsStr}`;
  }
  const res = await anyFetch(path, opts);
  const data = await res.json();
  return data;
};
const generateCacheKey = () => 'subscriptionGiftPlans';

export const useGiftSubscription = (
  params: QueryParams = {},
  opts: RequestInit = {},
): ResourceResult<SubscriptionPlan> =>
  useResource(params, opts, subscriptionFetcher, generateCacheKey());

export const fetchSSRSubscriptionGiftPlan = async (
  auth: Auth,
  opts: RequestInit = {},
): Promise<any> => {
  const res = await subscriptionFetcher({}, opts);
  auth.setCache(generateCacheKey(), res);
};

export default useGiftSubscription;
