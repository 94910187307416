import React, { FunctionComponent } from 'react';
import { FetchStatus } from '~/resources';
import { NavLink } from 'react-router-dom';
import { usePageContext } from './context.page';

import c from './PageMain.sass';
import SidebarSceleton from '../Sceletons';

interface Props {
  onClick: () => void;
}
const SubscribePage: FunctionComponent<Props> = ({ onClick }) => {
  const { profile, profileStatus } = usePageContext();

  const status = profileStatus?.[0] ?? undefined;
  const isLoading = !status || status === FetchStatus.Fetching;
  // const isProfile = profile && profile[0];
  const isAdmin = profile && profile[0] && profile[0].is_admin;
  const isSubscribed = profile && profile[0] && profile[0].is_subscribed;

  const renderSubButton = () => (
    <li>
      <NavLink
        to="/subscription"
        className={c.active}
        activeClassName={c.active}
        onClick={onClick}
      >
        Подписка
        <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="8" cy="8" r="8" fill="#FF6B40" />
          <path d="M4.30759 10.6396L5.95405 8.78667L3.58115 8.30458C3.16934 8.22833 3 8.10146 3 7.79688C3 7.69542 3.02425 7.59375 3.07254 7.46688L3.65369 5.56333C3.75049 5.23354 3.87192 5.08125 4.08956 5.08125C4.2108 5.08125 4.35589 5.13188 4.54967 5.23354L6.75303 6.29958L6.46246 3.76146C6.43841 3.68542 6.43841 3.60917 6.43841 3.50771C6.43841 3.12687 6.58369 3 7.04361 3H8.95659C9.36821 3 9.56199 3.15229 9.56199 3.53292L9.53754 3.76146L9.24717 6.29958L11.4263 5.23354C11.6201 5.13188 11.7652 5.08125 11.8864 5.08125C12.08 5.08125 12.2253 5.23354 12.3221 5.56333L12.9275 7.46688C12.976 7.59375 13 7.69542 13 7.79688C13 8.10146 12.8307 8.22833 12.419 8.30458L10.0219 8.78667L11.6926 10.6396C11.8621 10.8173 11.9589 10.995 11.9589 11.1473C11.9589 11.3248 11.8379 11.5025 11.6201 11.6548L10.0704 12.8223C9.90086 12.9492 9.77962 13 9.65878 13C9.465 13 9.34396 12.8731 9.17442 12.5685L7.98807 10.335L6.82577 12.5685C6.65604 12.8477 6.51095 13 6.34142 13C6.22038 13 6.07509 12.9492 5.90555 12.8223L4.38013 11.6548C4.1623 11.4771 4.04106 11.3248 4.04106 11.1473C4.04106 10.995 4.13785 10.8173 4.30759 10.6396Z" fill="#FFB78F" />
        </svg>
      </NavLink>
    </li>
  );

  const renderVideoButton = () => (
    <li>
      <NavLink
        exact
        to="/video"
        className={c.menu_search}
        activeClassName={c.active}
        onClick={onClick}
      >
        Видео
      </NavLink>
    </li>
  );

  if (isLoading) {
    return <SidebarSceleton />;
  }

  if (isSubscribed || isAdmin) {
    return renderVideoButton();
  }

  return renderSubButton();
};

export default SubscribePage;
