/* eslint-disable camelcase */
import React, { FunctionComponent } from 'react';

import Topic from '../Topic';
import Image from '../Image';
import { Post } from '~/resources/models';
import c from './HeaderSuper.sass';
import { useConfig } from '~/resources';
import Rubric from '../Rubric';

type Props = {
  post: Post;
}

const HeaderSuper: FunctionComponent<Props> = ({
  post: {
    title, description, image = {}, authors, topic, published_at, image_title, partner_material, rubric
  },
}) => {
  const { data: config } = useConfig();
  const imageConfig = config ? config.images : {};

  return (
    <div className={c.headerSuper}>
      <div className={c.wrapper}>
        <div className={c.topicWrap}>
          <div className={c.labels}>
            {topic && topic.title && (
              <Topic
                title={topic.title}
                subtitle={published_at ? new Date(+published_at * 1000).toLocaleDateString('ru') : ''}
                href={`/topic/${topic.slug}`}
                colorOrange={!!partner_material}
              />
            )}
            {rubric && <Rubric name={rubric.name} href={`/rubric/${rubric.slug}`} />}
          </div>
          <h1 className={c.title}>{title}</h1>
        </div>
        {image && (
          <Image
            {...image}
            mode="super_cover"
            className={c.image}
            imageConfig={imageConfig}
            alt={title}
          />
        )}
        <div className={c.gradient} />
      </div>
      {image_title && (
        <div className={c.imageTitle}>
          { image_title}
          {' '}
        </div>
      )}
      <p className={c.description}>{description}</p>
      {(Array.isArray(authors) && authors.length > 0) && (
        <div className={c.footer}>
          <div className={c.authors}>
            <div className={c.authors_title}>{(authors.length === 1) ? 'Автор' : 'Авторы'}</div>
            <div>
              {authors.map((el, i) => (
                <div key={i}>
                  {el.name}
                  {el.position ? ` (${el.position})` : ''}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {partner_material?.image ? (
        <div className={c.footer}>
          <div className={c.authors} style={{ alignItems: 'center' }}>
            <div className={c.authors_title}>Автор</div>
            <div>Reminder+</div>
            <Image
              {...partner_material.image}
              mode="only_original"
              className={c.image}
              imageConfig={imageConfig}
              resizeByHeight
            />
          </div>
        </div>
      ) : partner_material && (
        <div className={c.footer}>
          <div className={c.authors}>
            <div className={c.authors_title}>Автор</div>
            <div>Reminder+</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HeaderSuper;
