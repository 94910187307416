/* eslint-disable camelcase */
import React, { FunctionComponent, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { LogoPage } from '../page';
import Topic from '../Topic';
import Image from '../Image';
import { Post } from '~/resources/models';

import c from './PageHeaderSuper.sass';
import { useConfig } from '~/resources';
import Rubric from '../Rubric';

type Props = {
  post: Post;
}

const PageHeaderSuper: FunctionComponent<Props> = ({
  post: {
    topic, published_at, title, image, partner_material, rubric
  },
}) => {
  const { data: config } = useConfig();
  const banner = config?.banner;
  const imageConfig = config ? config.images : {};
  const ref = useRef<HTMLDivElement>(null);
  const refLogo = useRef<HTMLDivElement>(null);

  const onScroll = () => {
    if (ref.current && refLogo.current) {
      if (ref.current.clientHeight < window.pageYOffset) {
        refLogo.current.style.opacity = '0';
      } else {
        if (banner) {
          refLogo.current.style.position = window.pageYOffset > 30 ? 'fixed' : 'initial';
        }
        refLogo.current.style.opacity = '1';
      }
    }
  };

  useEffect(() => {
    onScroll();
    document.addEventListener('scroll', onScroll);
    return () => document.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <div className={c.pageHeaderSuper} ref={ref}>
      <div className={c.logoStart}>
        <div className={c.logo} ref={refLogo}>
          <LogoPage />
        </div>
      </div>
      <Link
        to="/"
        className={c.logoEnd}
        aria-label="Логотип сайта"
      >
        <div className={c.logo}>
          <LogoPage />
        </div>
      </Link>
      <div className={c.content}>
        <div className={c.labels}>
          {topic && topic.title && (
            <Topic
              title={topic.title}
              subtitle={published_at ? new Date(+published_at * 1000).toLocaleDateString('ru') : ''}
              href={`/topic/${topic.slug}`}
              colorOrange={!!partner_material}
            />
          )}
          {rubric && <Rubric name={rubric.name} href={`/rubric/${rubric.slug}`} />}
        </div>
        <h1 className={c.title}>{title}</h1>
      </div>
      {image && (
        <div className={c.image}>
          <Image
            {...image}
            mode="super_cover"
            imageConfig={imageConfig}
            alt={title}
          />
        </div>
      )}
    </div>
  );
};

export default PageHeaderSuper;
