import React, {
  FunctionComponent, useEffect, useState, useRef,
} from 'react';

import c from './AsteriskHeader.sass';

interface Props {}

const Asterisk: FunctionComponent<Props> = (props) => {
  const [scroll, setScroll] = useState(0);
  const footer = useRef<HTMLElement>();

  useEffect(() => {
    footer.current = document.getElementById('footer');
    onScroll();
    document.addEventListener('scroll', onScroll);
    return () => document.removeEventListener('scroll', onScroll);
  }, []);

  const onScroll = () => {
    setScroll(window.pageYOffset);
  };

  const onClick = () => {
    window.scrollTo({ behavior: 'smooth', top: 0 });
  };

  const isHide = () => ((footer.current && scroll >= footer.current.offsetTop) || scroll <= 0);

  return (
    <div
      className={c.asterisk}
      onClick={onClick}
      style={{
        transform: (scroll / 1000 < 1) ? `scale(${scroll / 1000})` : '',
        opacity: isHide() ? '0' : '1',
      }}
    >
      <div className={c.wrap}>
        <svg width="50" height="46" viewBox="0 0 86 81" style={{ transform: `rotate(-${scroll / 3}deg)` }}>
          <path d="M11.245,61.881,25.4,46.872,5,42.967c-3.542-.618-5-1.645-5-4.112a7.044,7.044,0,0,1,.624-2.673l5-15.419c.832-2.671,1.877-3.9,3.748-3.9a9.433,9.433,0,0,1,3.957,1.234l18.949,8.635-2.5-20.559a6.3,6.3,0,0,1-.207-2.055C29.57,1.028,30.82,0,34.775,0H51.227c3.54,0,5.206,1.234,5.206,4.317l-.21,1.851-2.5,20.559,18.74-8.635a9.433,9.433,0,0,1,3.957-1.234c1.665,0,2.914,1.234,3.747,3.9l5.206,15.419A7.011,7.011,0,0,1,86,38.855c0,2.467-1.456,3.495-5,4.112l-20.615,3.9L74.756,61.881c1.458,1.439,2.29,2.879,2.29,4.112,0,1.438-1.041,2.877-2.914,4.111L60.805,79.561A6.143,6.143,0,0,1,57.266,81c-1.667,0-2.707-1.028-4.165-3.495L42.9,59.414l-10,18.092C31.442,79.766,30.194,81,28.736,81a6.661,6.661,0,0,1-3.748-1.439L11.869,70.1C10,68.664,8.953,67.431,8.953,65.993c0-1.234.832-2.673,2.292-4.112" />
        </svg>
      </div>
    </div>
  );
};

export default Asterisk;
