export const HookTinkoffDatamindInit = (): void => {
  const src = 'https://static.datamind.ru/upixel/watch.js?partnerid=721b56ac-ac3b-4508-9702-3375916159c2';
  const code = `
        Datamind.init({
          meta: {
            title: "Три когнитивных ошибки, которые мешают инвесторам",
              utm_source: "reminder1120_tb",
              utm_campaign: "black.kopilka",
              utm_medium: "ntv.fix"
            },
          lid:538, cid: [120013],
          ga: "UA-9110453-17",
          insertIframe: true, dayLimitSyncIframe: 5,
          insertTicker: true, step: 15, count: 25,
          mouseTrack: false, scrollTrack: false
        });
      `;
  if (typeof window !== 'undefined') {
    const script = document.createElement('script');
    script.src = src;
    script.appendChild(document.createTextNode(code));
    document.head.appendChild(script);
  }
};

export const HookTinkoffDatamindDestroy = (): void => {
  const scriptFiles = [
    'https://static.datamind.ru/upixel/watch.js?partnerid=721b56ac-ac3b-4508-9702-3375916159c2',
    'https://static.datamind.ru/upixel/datamind.js',
  ];
  const scriptBody = "ga('DataMind.send', 'pageview');";
  const gaFile = 'https://www.google-analytics.com/analytics.js';
  const remove = (el: Node) => {
    console.log(el);
    if (el.parentNode) {
      el.parentNode.removeChild(el);
    }
  };
  if (typeof window !== 'undefined') {
    // Удаляем скрипты домена datamind.ru
    scriptFiles.forEach((scriptFile) => {
      document.head
        .querySelectorAll(`[src~="${scriptFile}"]`)
        .forEach(remove);
    }, []);
    // Удаляем скрипты гугл аналитики
    const scripts = document.head.getElementsByTagName('script');
    for (let i = 0; i < scripts.length; i += 1) {
      const script = scripts[i];
      if (script.innerHTML.includes(scriptBody)) {
        remove(script);
      }
    }
    // Удаляем дублирующуюся гугл аналитику
    const gaScripts = document.head.querySelectorAll(`[src~="${gaFile}"]`);
    if (gaScripts.length > 1) {
      for (let i = 1; i < gaScripts.length; i += 1) {
        remove(gaScripts[i]);
      }
    }
  }
};
