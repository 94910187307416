import React, { FunctionComponent, useEffect } from 'react';
import useReports from '~/resources/useReports';
import ReportPreview from './ReportPreview';
import classes from './ReportsList.sass';
import { Loader } from '~/components';
import { QueryParams, ResourceMeta } from '~/resources';

interface Props {
  page: number;
  onMeta: (meta: ResourceMeta) => void,
  onLoading: (loading: boolean) => void,
}

const ReportsList: FunctionComponent<Props> = ({
  page, onMeta, onLoading,
}) => {
  const params: QueryParams = { type: 'report', page: String(page) };
  const title = 'Отчеты Reminder';
  const subtitle = '  — это сборники материалов, в которых глубоко исследуется тема и даются практические советы';
  const { meta, fetchBy, data: reports } = useReports(params);
  useEffect(() => fetchBy(), [page]);
  useEffect(() => {
    if (meta) {
      onMeta(meta);
    }
  }, [meta]);
  useEffect(() => {
    onLoading(!reports);
  }, [reports]);

  if (!reports) {
    return <Loader />;
  }

  return (
    <>
      <div className={classes.reportsList}>
        <div className={classes.reportsList__header}>
          <h2>
            <span className={classes.reportsList__title}>{title}</span>
            <span>{subtitle}</span>
          </h2>
          <div className={classes.reportsList__hr} />
        </div>

        <div className={classes.reportsList__list}>
          {reports.map((report, index) => (
            <ReportPreview key={report.id} report={report} index={index} />
          ))}
        </div>
      </div>
    </>
  );
};

export default ReportsList;
