import React, {
  FunctionComponent, useEffect, useRef, useState,
} from 'react';
import { Redirect, useParams } from 'react-router';

import {
  ContentPost,
  HeaderPost,
  Loader,
  Page,
  Recommendations,
  usePageContext,
} from '~/components';
import usePost from '~/resources/usePost';
import config from '~/config';
import Helmeter from '~/screens/Helmeter';
import JsonLdPost from '~/screens/jsonLdPost';
import { usePartnerMaterialHooks } from '~/utils/PartnerMaterialsHooks';
import { isResourceFetchError } from '~/components/Subscription/utils';
import Banner from '~/components/Banner';
import { FetchStatus } from '~/resources';
import disableScroll from '~/utils/disableScroll';
import increasePostViewFetcher, { updatePostViewFetcher } from '~/resources/usePostView';
import { useResetScroll } from './screen';

const PostScreen: FunctionComponent = () => {
  const { slug } = useParams<{ slug: string }>();
  useResetScroll(slug);

  const {
    auth, profile, profileStatus, alertModal,
  } = usePageContext();
  const [_, setLastPost] = usePageContext().lastPost;

  // useScreenTitle(res.data, loading);
  // const { pathname, search } = useLocation();
  // const ampLink = `${config('APP_HOST')}/amp${pathname}`;
  const canonicalLink = `${config('APP_HOST')}/post/${slug}`;
  const { status, fetchBy, data: post } = usePost(slug, auth);
  const [isReadyPaywall, setIsReadyPaywall] = useState(false);
  // ToDo: remove kostyl'
  const isFirstLoad = useRef(profile && !profile[0]);
  const [isFirstLook, setIsFirstLook] = useState(false);

  const increasePostView = async () => {
    if (!auth?.DATA.postToken
      || typeof auth?.DATA.postToken !== 'string') {
      const data = await updatePostViewFetcher(slug);
      if (data.result) {
        increasePostViewFetcher({ token: data.result });
      }
      return;
    }
    increasePostViewFetcher({ token: auth?.DATA.postToken });
    auth?.setCache('postToken', undefined);
  };

  useEffect(() => {
    if (slug) {
      // const key = new URLSearchParams(search).get('key') || null;
      if (post?.slug === slug) {
        increasePostView();
      } else {
        setIsFirstLook(true);
      }
      fetchBy();
    }
  }, [slug]);

  useEffect(() => {
    if (!post || !isFirstLook) { return; }
    setIsFirstLook(false);
    increasePostView();
  }, [post]);

  useEffect(() => {
    // eslint-disable-next-line camelcase
    if (post?.is_preview && profileStatus && profileStatus[0] === FetchStatus.Fetched) {
      if (
        isFirstLoad.current
        && profile
        && profile[0]
        && (profile[0].is_subscribed || profile[0].is_admin)
      ) {
        fetchBy(true);
        isFirstLoad.current = false;
        // disableScroll(false);
      } else {
        setIsReadyPaywall(true);
      }
    }
  }, [profile, profileStatus, post]);

  usePartnerMaterialHooks(post);

  if (!post) {
    return isResourceFetchError(post, status) ? <Redirect to="/404" /> : <Loader />;
  }
  if ((auth?.AUTH as any).access_token) {
    setLastPost(post);
  }

  let img: string;
  // eslint-disable-next-line camelcase
  if (post?.og_image) {
    // eslint-disable-next-line camelcase
    img = post?.og_image.src;
  } else {
    img = post?.rubric?.slug === 'chto-novogo'
      ? post?.image?.src || `${config('APP_HOST')}/public/chto-novogo.jpg`
      : post?.image?.src;
  }

  return (
    <>
      <Banner />
      <Helmeter
        title={`${post.title} - Reminder`}
        description={post.description}
        metaTitle={post.meta_title ? `${post.meta_title} - Reminder` : `${post.title} - Reminder`}
        metaDescription={post.meta_description || post.description}
        img={img}
        type="article"
        link={[
          // { rel: 'amphtml', href: ampLink },
          { rel: 'canonical', href: canonicalLink },
        ]}
        srcScripts={['https://yookassa.ru/checkout-widget/v1/checkout-widget.js']}
      />
      <Page>
        <JsonLdPost post={post} />
        <HeaderPost post={post} />
        <ContentPost
          post={post}
          checkLimit={false}
          isPaywallReady={isReadyPaywall}
        />
        <Recommendations postExcludeId={post.id} />
      </Page>
    </>
  );
};

export default PostScreen;
