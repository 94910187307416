import React, { FunctionComponent, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import config from '~/config';
import DonateUnsubscribeForm from '~/components/Donate/DonateUnsubscribeForm';
import useWindowDimensions from '~/utils/useWindowDimensions';

import logo from '~/images/logo.svg';

import c from '~/components/Donate/Donate.sass';
import Helmeter from './Helmeter';

const DonateUnsubscribeScreen: FunctionComponent = () => {
  const { pathname } = useLocation();
  // const ampLink = `${config('APP_HOST')}/amp${pathname}`;
  const canonicalLink = pathname.match(/donate/) !== null ? `${config('APP_HOST')}/donate` : `${config('APP_HOST')}/subscription`;

  const { width } = useWindowDimensions();
  const isDesktop = width > 690;

  // Костыль для асинхронных запросов, чтобы при ssr к картинкам в src добавлялся префикс /static
  const [show, setShow] = useState(false);
  useEffect(() => {
    setShow(true);
  }, []);
  if (!show) return null;

  return (
    <>
      <Helmeter
        title="Поддержите Reminder. Инвестируйте в себя."
        metaTitle="Поддержите Reminder. Инвестируйте в себя."
        link={[
          // { rel: 'amphtml', href: ampLink },
          { rel: 'canonical', href: canonicalLink },
        ]}
      />
      {/* Title */}
      <div className={c.container} style={{ height: '100vh' }}>
        <div className={c.wrap}>
          <a href="/">
            <img src={logo} className={c.logo} alt="#" />
          </a>
          <div className={c.titleContainer}>
            <div className={c.titleBox}>
              <p className={c.title}>Личный кабинет.</p>
            </div>
          </div>
          {/* Form */}
          <div className={c.description}>
            Чтобы зайти в профиль и отписаться, напомните email
          </div>
          <DonateUnsubscribeForm />
        </div>
      </div>
    </>
  );
};

export default DonateUnsubscribeScreen;
