import React, { FunctionComponent } from 'react';
import { useParams } from 'react-router';
import { useResetScroll } from './screen';
import { Page, Paginator } from '~/components';
import RubricPostList from '~/components/post/RubricPostList';
import config from '~/config';
import Helmeter from '~/screens/Helmeter';
import JsonLd from '~/screens/jsonLd';
import Banner from '~/components/Banner';

type Params = {
    rubric: string
}

const RubricScreen: FunctionComponent = () => {
  const { rubric } = useParams<Params>();
  useResetScroll(rubric);

  const canonicalLink = `${config('APP_HOST')}/${`rubric/${rubric}`}`;

  return (
    <>
      <Banner />
      <Page>
        <Helmeter link={[{ rel: 'canonical', href: canonicalLink }]} />
        <JsonLd name={rubric} />
        <Paginator>
          {(page, handleMeta, handleLoading) => (
            <RubricPostList
              slug={rubric}
              page={page}
              onMeta={handleMeta}
              onLoading={handleLoading}
            />
          )}
        </Paginator>
      </Page>
    </>
  );
};

export default RubricScreen;
