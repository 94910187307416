import React, { FunctionComponent, useEffect, useState } from 'react';
import { Redirect, useLocation, useParams } from 'react-router';
import {
  Loader, Page, PageReportHeader,
  ReportContent, ReportHeader,
} from '~/components';
import FormContext from '~/components/Reports/Form';
import { isResourceFetchError } from '~/components/Subscription/utils';
import useReport from '~/resources/useReport';
import Helmeter from './Helmeter';
import { useResetScroll } from './screen';
import Banner from '~/components/Banner';

const ReportScreen: FunctionComponent = () => {
  const { slug } = useParams<{ slug: string }>();
  useResetScroll(slug);

  const [screenMd, setScreenMd] = useState(false);
  const { search } = useLocation();

  const key = new URLSearchParams(search).get('key');
  const { status, fetchBy, data: report } = useReport(slug, key);

  useEffect(() => {
    if (slug) {
      fetchBy();
    }
  }, [slug]);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const onResize = () => setScreenMd(window.innerWidth < 1024);
    onResize();
    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, []);

  if (!report) {
    return isResourceFetchError(report, status) ? <Redirect to={'/404'}/> : <Loader/>
  }

  let gradientStyle = `linear-gradient(${report.gradient.angle}deg`;
  report.gradient.colors.forEach(({ color, offset }, index) => {
    gradientStyle += `, ${color} ${offset * 100}%${index + 1 === report.gradient.colors.length ? ')' : ''}`;
  });

  return (
    <>
      <Banner />
      <Helmeter
        title={report.title}
        description={report.description}
        metaTitle={report.meta_title || report.title}
        metaDescription={report.meta_description || report.description}
        img={report?.cover?.src}
        srcScripts={['https://yookassa.ru/checkout-widget/v1/checkout-widget.js']}
      />

      <FormContext>
        {!screenMd && <PageReportHeader report={report} gradient={gradientStyle} />}
        <Page hideSeparator>
          {screenMd && <ReportHeader report={report} gradient={gradientStyle} />}
          <ReportContent report={report} />
        </Page>
      </FormContext>
    </>
  );
};

export default ReportScreen;
