import React, { FunctionComponent } from 'react';

const ReminderLogo: FunctionComponent = () => (

  <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path id="Vector" d="M9.64391 14.3554L7.93387 11.1298L6.12029 14.326C5.81516 14.8855 5.56033 15.0707 5.14696 14.9305C5.00925 14.8838 4.88299 14.8024 4.73485 14.6731L2.43502 12.9605C2.0349 12.6687 1.88741 12.4241 1.99409 12.1096C2.05329 11.935 2.19291 11.7492 2.42524 11.5179L4.94935 8.83226L1.36217 8.08392C1.24725 8.08346 1.14377 8.04838 1.00607 8.00167C0.4892 7.82637 0.387923 7.55887 0.612999 6.89525L1.54784 4.13892C1.74886 3.54621 2.05051 3.33632 2.5671 3.51153L2.86494 3.65308L6.16784 5.23959L5.78596 1.60892C5.74295 1.28213 5.74515 1.04974 5.80435 0.875193C5.8987 0.597016 6.17691 0.456219 6.67137 0.469845L9.55085 0.473341C9.7471 0.460835 9.89679 0.473095 10.0345 0.5198C10.4479 0.660001 10.5368 0.963851 10.4393 1.59167L9.92194 5.26807L13.2531 3.71374C13.5776 3.55009 13.8656 3.49372 14.0723 3.56382C14.3132 3.64552 14.4958 3.90004 14.5958 4.28468L15.4231 7.05478C15.5121 7.35864 15.5219 7.55658 15.4627 7.73113C15.3683 8.0093 15.1369 8.12544 14.6409 8.22886L11.0293 8.91163L13.4927 11.6143C13.789 11.9864 13.9248 12.2656 13.8415 12.5111C13.7823 12.6856 13.6424 12.8713 13.3876 13.0565L11.0575 14.7172C10.7097 14.95 10.4438 15.0544 10.2029 14.9727C9.99617 14.9026 9.80247 14.6808 9.64391 14.3554Z" fill="#F85B2E" />
  </svg>

);

export default ReminderLogo;
