import React, { FunctionComponent } from 'react';
import { useLocation, useParams } from 'react-router';
import { Page, Paginator } from '~/components';
import config from '~/config';
import TopicPostList from '~/components/post/TopicPostList';
import Banner from '~/components/Banner';
import { useResetScroll } from './screen';
import JsonLd from './jsonLd';
import Helmeter from './Helmeter';

type Params = {
  topic: string;
};

const TopicScreen: FunctionComponent = () => {
  const { topic } = useParams<Params>();
  useResetScroll(topic);

  const { pathname } = useLocation();
  // const ampLink = `${config('APP_HOST')}/amp${pathname}`;
  const canonicalLink = `${config('APP_HOST')}/${`topic/${topic}`}`;

  return (
    <>
      <Banner />
      <Page>
        <Helmeter
          link={[
            // { rel: 'amphtml', href: ampLink },
            { rel: 'canonical', href: canonicalLink },
          ]}
        />
        <JsonLd name={topic} />
        <Paginator>
          {(page, handleMeta, handleLoading) => (
            <TopicPostList slug={topic} page={page} onMeta={handleMeta} onLoading={handleLoading} />
          )}
        </Paginator>
      </Page>
    </>
  );
};

export default TopicScreen;
