/* eslint-disable camelcase */
import React, { FunctionComponent, useEffect } from 'react';
import { Link } from 'react-router-dom';
import useNews from '~/resources/useNews';
import { Loader } from '~/components';

import c from './ListNews.sass';
import PreviewNews from './PreviewNews';

const ListNews: FunctionComponent = () => {
  const URL_ALL_NEWS = '/rubric/vopros/';
  const { fetchBy, data: news } = useNews();

  useEffect(() => fetchBy(), []);

  if (!news) {
    return <Loader />;
  }

  return (
    <div className={c.container}>
      <p className={c.title}>Наша рассылка</p>
      <div className={c.list}>
        {news.map((post, i) => (
          <PreviewNews key={i} post={post} />
        ))}
      </div>
      <Link to={URL_ALL_NEWS} className={c.button}>
        Все выпуски
      </Link>
    </div>
  );
};

ListNews.displayName = 'ListNews';

export default ListNews;
