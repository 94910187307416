import React, { FunctionComponent } from 'react';

import { HeaderPage, MenuPage, FooterPage } from '.';
import { HeaderLongread } from '../longread';
import c from './PageMain.sass';
import AlertModal from '../Alert/AlertModal';
import { AuthModal } from '../Auth';

interface Props {
    dark?: boolean;
    hideSeparator?: boolean
}

const Page: FunctionComponent<Props> = ({
  dark = false,
  hideSeparator = false,
  children,
}) => (
  <>
    <HeaderLongread show={false} />
    <div className={c.page + (dark ? ` ${c.dark}` : '')}>
      <MenuPage />
      <HeaderPage forPage />
      <div className={`${c.content} ${c.page_content} ${!hideSeparator ? c.page_content_with_separator : ''}`}>
        {children}
      </div>
      <FooterPage className={c.page_footer} />
      <AuthModal />
      <AlertModal />
    </div>
  </>
);

export default Page;
