/* eslint-disable camelcase */
import React, {
  FunctionComponent, useEffect, useRef, MutableRefObject, useState,
} from 'react';
import { Link } from 'react-router-dom';
import { LogoPage, Topic } from '~/components';
import BuyButton from './BuyButton';
import ReportPrice from './ReportPrice';
import classes from './PageReportHeader.sass';
import { Report } from '~/resources/models';
import { useFormContext } from './Form';
import config from '~/config';
import { useConfig } from '~/resources';

interface Props {
  report: Report;
  gradient: string;
}

const FOOTER_HEIGHT = 1050;

const PageReportHeader: FunctionComponent<Props> = ({ report, gradient }) => {
  const ref = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
  const refLogo = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
  const headerBuyButtonRef = useRef<HTMLButtonElement>() as MutableRefObject<HTMLButtonElement>;
  const bottomBuyButtonRef = useRef<HTMLButtonElement>() as MutableRefObject<HTMLButtonElement>;
  const {
    cover_origin, title, active_price, pre_order_price, caption, status,
  } = report;

  const [, setOrderingReport] = useFormContext().orderingReport;
  const { data: configData } = useConfig();
  const banner = configData?.banner;

  const [hideBottomBuy, setHideBottomBuy] = useState(false);
  const hideBottomBuyRef = useRef(hideBottomBuy);

  useEffect(() => {
    hideBottomBuyRef.current = hideBottomBuy;
  }, [hideBottomBuy]);

  const getBodyScrollTop = () => window.pageYOffset
    || (document.documentElement && document.documentElement.scrollTop)
    || (document.body && document.body.scrollTop);

  const onScroll = () => {
    if (!(ref.current && refLogo.current)) return;

    if (ref.current.clientHeight < window.pageYOffset) {
      refLogo.current.style.opacity = '0';
    } else {
      if (banner) {
        refLogo.current.style.position = window.pageYOffset > 30 ? 'fixed' : 'initial';
      }
      refLogo.current.style.opacity = '1';
    }

    const headerButtonRelationElement = headerBuyButtonRef.current as HTMLElement;
    const headerButtonY = headerButtonRelationElement.getBoundingClientRect().y;

    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    const scrollHeight = Math.max(
      document.body.scrollHeight, document.documentElement.scrollHeight,
      document.body.offsetHeight, document.documentElement.offsetHeight,
      document.body.clientHeight, document.documentElement.clientHeight,
    );

    if (
      (getBodyScrollTop() >= scrollHeight - vh - FOOTER_HEIGHT && !hideBottomBuyRef.current)
      || headerButtonY > 0
    ) {
      setHideBottomBuy(true);
    } else if (
      getBodyScrollTop() < scrollHeight - vh - FOOTER_HEIGHT
      && hideBottomBuyRef.current
      && headerButtonY < 0
    ) {
      setHideBottomBuy(false);
    }
  };

  useEffect(() => {
    headerBuyButtonRef.current.onclick = () => {
      setOrderingReport(report);
    };

    bottomBuyButtonRef.current.onclick = () => {
      setOrderingReport(report);
    };

    onScroll();
    document.addEventListener('scroll', onScroll);
    return () => document.removeEventListener('scroll', onScroll);
  }, []);

  const price = (() => {
    if (status === 'pre_order' && pre_order_price) return pre_order_price;
    if (status === 'active' && active_price) return active_price;
    return 0;
  })();

  const noiseSrc = `${config('APP_HOST')}/public/noise-bg-report.png`;

  return (
    <div
      className={classes.pageReportHeader}
      style={{ background: gradient }}
      ref={ref}
    >
      <img className={classes.pageReportHeader__noise} src={noiseSrc} alt="" />

      <div className={classes.logoStart}>
        <div className={classes.logo} ref={refLogo}>
          <LogoPage />
        </div>
      </div>

      <Link to="/" className={classes.logoEnd} aria-label="Логотип сайта">
        <div className={classes.logo}>
          <LogoPage />
        </div>
      </Link>

      <div className={classes.pageReportHeader__content}>
        <div className={classes.pageReportHeader__body}>
          <Topic title="отчеты" href="/reports" />
          <h1 className={classes.pageReportHeader__title}>{title}</h1>

          <div className={classes.pageReportHeader__price}>
            <ReportPrice
              {...{
                active_price,
                pre_order_price,
                status,
              }}
              color="rgb(255, 255, 255)"
              fontSize={39}
              fontSizeDiscount={20}
            />
          </div>

          <button
            className={classes.pageReportHeader__button}
            ref={headerBuyButtonRef}
            type="button"
          >
            {price ? 'Купить' : 'Получить'}
          </button>
          <p className={classes.pageReportHeader__date}>
            {caption}
          </p>
        </div>
        <img className={classes.pageReportHeader__img} src={cover_origin.src} alt="" />
      </div>

      <BuyButton
        hide={hideBottomBuy}
        ref={bottomBuyButtonRef}
        price={price}
        caption={caption}
        style={{
          position: 'fixed',
          bottom: 30,
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      />
    </div>
  );
};

export default PageReportHeader;
