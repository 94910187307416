import React, { FunctionComponent, useEffect, useState } from 'react';
import { Loader, SubscriptionFlowStep, usePageContext } from '../components';
import socAuthSendCode from '~/resources/socAuthSendCode';
import c from '../components/Reports/Form.sass';
import useSubscriptionFlow from '~/resources/useSubscriptionFlow';

const AuthLoadingScreen: FunctionComponent = () => {
  const [status, setStatus] = useState('init');
  const { auth } = usePageContext();
  const { subscriptionFlowVal, buyAfterAuth } = useSubscriptionFlow();

  useEffect(() => {
    if (auth) {
      socAuthSendCode(auth).then((res) => setStatus(res));
    }
  }, []);

  useEffect(() => {
    if (status === 'success') {
      if (
        subscriptionFlowVal
        && subscriptionFlowVal.step === SubscriptionFlowStep.AUTH
      ) {
        buyAfterAuth();
      }
      const timeout = setTimeout(() => {
        window.opener.location.reload();
        window.close();
      }, 1000);
      return () => clearTimeout(timeout);
    }
    if (status === 'init') {
      const interval = setInterval(() => {
        window.location.reload();
      }, 5000);
      return () => clearInterval(interval);
    }
    return () => undefined;
  }, [status]);

  return (
    <>
      {status === 'error' && (
        <div className={c.modal__content}>
          <div className={c.form}>
            <h1 className={`${c.form__title} ${c.form__title_ok}`}>Что-то пошло не так...</h1>
            <p className={`${c.form__subTitle}`}>Попробуйте обновить страницу</p>
            <div className={`${c.form__button_block}`}>
              <button
                className={`${c.form__submit} ${c.form__submit_ok} ${c.form__submit_red}`}
                onClick={() => { window.location.reload(); }}
                type="button"
              >
                Обновить
              </button>
              <button
                className={`${c.form__submit} ${c.form__submit_ok}`}
                onClick={() => {
                  window.opener.location.reload();
                  window.close();
                }}
                type="button"
              >
                Закрыть
              </button>
            </div>
          </div>
        </div>
      )}
      {status === 'init' && (
        <div className={c.modal__content}>
          <Loader />
        </div>
      )}
    </>
  );
};

export default AuthLoadingScreen;
