/* eslint-disable camelcase */
import React, { FunctionComponent } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { Image, Topic } from '..';
import { useConfig } from '~/resources';
import { Post } from '~/resources/models';
import c from './PreviewSuper.sass';
import Rubric from '../Rubric';

type Props = {
  post: Post;
  showRubricLabel?: boolean
}

const PreviewSuper: FunctionComponent<Props> = ({
  post: {
    id, topic, published_at, title, description, image_preview, preview_mode = 'preview', slug, partner_material, rubric
  }, showRubricLabel = true,
}) => {
  const { data: config } = useConfig();
  const imageConfig = config ? config.images : {};

  const history = useHistory();
  const urlPost = `/super/${slug}`;
  const urlTopic = topic && `/topic/${topic.slug}`;
  const urlRubric = rubric && `/rubric/${rubric.slug}`;
  const onClickImage = () => history.push(urlPost);

  return (
    <div className={c.container}>
      <div className={c.content}>
        <div className={c.content__labels}>
          {(topic && topic.title) && (
            <Topic
              title={topic.title}
              subtitle={new Date(+published_at * 1000).toLocaleDateString('ru')}
              href={urlTopic}
              colorOrange={!!partner_material}
            />
          )}
          {showRubricLabel && rubric && rubric.name && <Rubric name={rubric.name} href={urlRubric}/>}
        </div>
        <Link to={urlPost} className={c.title}>{title}</Link>
        {description && (
          <div className={c.description}>{description}</div>
        )}
      </div>
      {image_preview && (
        <div className={c.imageWrap}>
          <Image
            {...image_preview}
            mode={preview_mode}
            imageConfig={imageConfig}
            contentEditable={undefined}
            onClick={onClickImage}
            alt={image_preview.alt || title}
          />
        </div>
      )}
    </div>
  );
};

export default PreviewSuper;
