/* eslint-disable camelcase */
import React, { FunctionComponent, useMemo } from 'react';
import { useRouteMatch } from 'react-router';
import { FetchStatus } from '~/resources';
import disableScroll from '~/utils/disableScroll';
import { AuthModalFrom, AuthModalState, usePageContext } from './context.page';

import c from './PageMain.sass';
import SidebarSceleton from '../Sceletons';

const ADMIN_URLS = ['/post/:slug', '/super/:slug', '/longread/:slug'];

const LoginPage: FunctionComponent = () => {
  const match = useRouteMatch<{ slug?: string }>();
  const { path, params: { slug } } = match;

  const {
    profile,
    profileStatus,
    authModal,
    alertModal,
  } = usePageContext();
  const [lastPost] = usePageContext().lastPost;

  const userName = (): string => {
    if (profile?.[0]) {
      if (profile[0].last_name === null) {
        return profile[0].name;
      }
      return `${profile[0].name} ${profile[0].last_name}`;
    }
    return '';
  };

  const onLogin = () => {
    if (authModal) {
      authModal[1]({ authModalState: AuthModalState.CHECK, authModalFrom: AuthModalFrom.NONE });
    }
    disableScroll(true);
  };

  const status = profileStatus?.[0] ?? undefined;
  const isLoading = !status || status === FetchStatus.Fetching;
  const isProfile = profile && profile[0];
  const isAdmin = profile && profile[0] && profile[0].is_admin;
  const isAdminUrl = ADMIN_URLS.indexOf(path) >= 0;

  const editUrl = useMemo<string | null>(() => {
    if (!isAdminUrl) {
      return null;
    }
    let prefix = '/admin/editor-post';
    if (path === '/longread/:slug') {
      prefix = '/admin/editor-longread';
    }
    if (lastPost?.partner_material) {
      prefix = '/admin/editor-partner';
    }
    return `${prefix}/${slug}`;
  }, [isAdminUrl]);

  const renderSkeleton = () => (
    <ul className={c.login_list}>
      <SidebarSceleton />
    </ul>
  );

  const renderLogin = () => (
    <ul className={c.login_list}>
      <li>
        <button
          className={c.login}
          type="button"
          onClick={onLogin}
        >
          <span>ВОЙТИ</span>
          <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8" cy="8" r="8" />
            <path d="M12.3536 8.35355C12.5488 8.15829 12.5488 7.84171 12.3536 7.64645L9.17157 4.46447C8.97631 4.2692 8.65973 4.2692 8.46447 4.46447C8.2692 4.65973 8.2692 4.97631 8.46447 5.17157L11.2929 8L8.46447 10.8284C8.2692 11.0237 8.2692 11.3403 8.46447 11.5355C8.65973 11.7308 8.97631 11.7308 9.17157 11.5355L12.3536 8.35355ZM4 7.5C3.72386 7.5 3.5 7.72386 3.5 8C3.5 8.27614 3.72386 8.5 4 8.5L4 7.5ZM12 7.5L4 7.5L4 8.5L12 8.5L12 7.5Z" />
          </svg>
        </button>
      </li>
    </ul>
  );

  const renderProfile = () => (
    <ul className={c.login_list}>
      <li>
        <a href="/profile" className={c.login}>
          <span>{userName()}</span>
          <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8" cy="8" r="8" />
            <path d="M12.3536 8.35355C12.5488 8.15829 12.5488 7.84171 12.3536 7.64645L9.17157 4.46447C8.97631 4.2692 8.65973 4.2692 8.46447 4.46447C8.2692 4.65973 8.2692 4.97631 8.46447 5.17157L11.2929 8L8.46447 10.8284C8.2692 11.0237 8.2692 11.3403 8.46447 11.5355C8.65973 11.7308 8.97631 11.7308 9.17157 11.5355L12.3536 8.35355ZM4 7.5C3.72386 7.5 3.5 7.72386 3.5 8C3.5 8.27614 3.72386 8.5 4 8.5L4 7.5ZM12 7.5L4 7.5L4 8.5L12 8.5L12 7.5Z" />
          </svg>
        </a>
      </li>
      {isAdmin && (
        <>
          <li>
            <a href="/admin" target="_blank" className={c.login}>
              <span>Управление</span>
            </a>
          </li>
          {editUrl && (
            <li>
              <a
                href={editUrl}
                rel="noreferrer"
                target="_blank"
              >
                Редактировать
              </a>
            </li>
          )}
        </>
      )}
    </ul>
  );

  if (isProfile) {
    return renderProfile();
  }

  if (!isLoading) {
    return renderLogin();
  }

  return renderSkeleton();
};

export default LoginPage;
